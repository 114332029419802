:root {
  --font-body-primary: "Roboto Flex", Roboto, Helvetica, Arial, sans-serif;
  --font-body-secondary: Roboto, Helvetica, Arial, sans-serif;
  --font-button-text: var(--font-body-primary);
  --font-logo: "Montserrat Alternates", sans-serif;
  --color-logo-primary: #eee;
  --color-text-primary: #ddd;
  --color-text-dark-primary: #112244;
  --color-text-highlight: rgb(243, 146, 0);
  --color-bg-primary: #112244;
  --color-bg-light: rgba(158, 173, 203, 50%);
  --color-button-bg: rgb(243, 146, 0);
  --color-button-bg-disabled: rgba(158, 173, 203, 80%);
  --color-page-nav-button-bg: rgba(255, 255, 255, 0.075);
  --color-input-bg: rgba(255, 255, 255, 0.1);
  --color-input-border: rgba(255, 255, 255, 0.5);
  --color-alert-error: rgba(141, 7, 7, 0.8);
  --color-alert-success: rgba(27, 115, 74, 0.743);
  --color-alert-bg: rgb(92, 109, 141);
  --color-modal-bg: rgba(255, 255, 255, 0.1);
  --color-modal-backdrop: rgba(10, 23, 49, 0.75);
  --color-card-default-bg: rgba(255, 255, 255, 0.04);
  --color-card-light-bg: rgba(158, 173, 203, 50%);
  --color-card-light-hover-bg: rgba(113, 131, 167, 0.39);
  --color-primary-darktheme: rgba(139, 150, 173, 60%);
  --color-secondary-darktheme: rgba(158, 173, 203, 50%);
  --color-bg-darktheme: rgba(255, 255, 255, 0.075);
  --color-bg-menu-sm: rgba(60, 74, 104, 0.9);
  --content-width-xl: 1400px;
  --content-width-lg: 1024px;
  --content-width-md: 768px;
  color-scheme: only light !important;
}

*,
::before,
::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  min-width: 0;
  /* flexbox overflow fix */
  outline: none;
  border: none;
}

body,
html {
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  /* for better rendering on WebKit browsers like Chrome and Safari */
  -moz-osx-font-smoothing: grayscale;
  /* for better rendering on Firefox and macOS */
  text-rendering: optimizeLegibility;
  /* enhances text rendering */
  font-size: 16px;
  line-height: 1.2;
  font-family: var(--font-body-primary);
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "slnt" 0,
    "wdth" 100,
    "GRAD" 0,
    "XTRA" 468,
    "YOPQ" 79,
    "YTAS" 750,
    "YTDE" -203,
    "YTFI" 738,
    "YTLC" 514,
    "YTUC" 712;
}

body {
  position: relative;
  height: 100dvh;
}

.hidden {
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

a,
a:visited,
a:focus {
  text-decoration: none;
  color: var(--color-text-primary);
}

.btn {
  position: relative;
  padding: 0.75rem 1.5rem;
  font-family: var(--font-button-text);
  font-size: 0.95rem;
  letter-spacing: 0.08ch;
  font-weight: 600;
  border-radius: 0.15rem;
  outline: 1px solid var(--CLR_MENU_BG_HIGHLIGHT);
  background-color: var(--color-button-bg);
  text-shadow: 1px 1px 1px rgb(30 30 30 / 65%);
  box-shadow: 1px 1px 1px rgb(30 30 30 / 65%);
  color: #fff;
  text-wrap: nowrap;
  -ms-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.btn:active {
  -ms-transform: scale(0.95) translateY(-1px);
  -o-transform: scale(0.95) translateY(-1px);
  -moz-transform: scale(0.95) translateY(-1px);
  -webkit-transform: scale(0.95) translateY(-1px);
  transform: scale(0.95) translateY(-1px);
}

.btn:disabled {
  background-color: var(--color-button-bg-disabled);
  pointer-events: none;
  cursor: not-allowed;
}

.btn--spinner {
  padding-left: 2.75rem;
  pointer-events: none;
}

.btn--spinner::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  -ms-transform: translate(55%, -50%);
  -o-transform: translate(55%, -50%);
  -moz-transform: translate(55%, -50%);
  -webkit-transform: translate(55%, -50%);
  transform: translate(55%, -50%);
  width: 1.35rem;
  height: 1.35rem;
  background: url(../assets/img/spinner.svg) center/cover no-repeat;
  z-index: 50;
}

.btn--dlg-circle {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  height: 32px;
  width: 32px;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 50%;
  text-align: center;
  color: var(--color-text-primary);
  background-color: var(--color-bg-light);
  cursor: pointer;
}

.btn--dlg-circle:before {
  display: grid;
  place-content: center;
  height: 100%;
  width: 100%;
  font-size: 1.16rem;
  font-weight: 300;
}

.btn--dlg-circle--close:before {
  content: "\2715";
}

.btn--dlg-circle--up:before {
  content: "\e316";
}

.md,
.lg,
.xl {
  display: none;
}

.nowrap {
  white-space: nowrap;
}

/********* LAYOUT AND NAVIGATION ELEMENTS *********/

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0.25em 0.7em 0 0.5em;
  z-index: 50;
  display: flex;
  justify-content: space-between;
}

.logo {
  position: relative;
  font-size: 0.6rem;
  padding-left: 4.75em;
  font-weight: 400;
  font-family: var(--font-logo);
  white-space: nowrap;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--color-logo-primary);
  text-shadow: 1px 2px 2px rgb(0 0 0 / 65%);
  color-scheme: only light;
}

.logo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5em;
  height: 5em;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 80%;
  background-image: url("../assets/logo/logo-white.png");
  color-scheme: only light;
}

.logo__brand {
  font-size: 2.85em;
  line-height: 1.15;
  white-space: nowrap;
}

.logo__sub {
  font-size: 1.05em;
  line-height: 0.85;
  letter-spacing: 0.068em;
  white-space: nowrap;
}

#menu-hamburger,
#menu-close {
  width: 36px;
  height: 36px;
  outline: none;
  border: none;
  text-align: center;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  /* remove the gray overlay color that appears when you tap the button */
  -ms-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

#menu-hamburger svg,
#menu-close svg {
  width: 100%;
  height: 100%;
  fill: #fff;
}

header #top-nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 24ch;
  overflow: hidden;
  z-index: 15;
  background-color: var(--color-bg-menu-sm);
  border: 1px solid var(--color-input-border);
  border-end-start-radius: 0.5rem;
  border-start-start-radius: 0.5rem;
  -ms-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  transition: transform 0.3s ease-out;
}

header #top-nav.active {
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

#menu-hamburger {
  align-self: end;
}

#top-nav.active #menu-close {
  position: absolute;
  top: 0.8rem;
  right: 0.7rem;
  z-index: 20;
}

#top-nav.active + #menu-hamburger {
  display: none;
}

#top-nav.active + #menu-close {
  display: block;
}

header #top-menu {
  display: flex;
  flex-direction: column;
  list-style: none;
}

header #top-menu .menu-item {
  padding: 1.25rem 0.75rem 1.25rem;
  margin-inline: 1px;
  font-size: 1.1rem;
  font-family: var(--font-body-secondary);
  font-weight: 500;
  letter-spacing: 0.1ch;
  text-shadow: 1px 1px 1px rgb(30 30 30 / 65%);
  text-wrap: nowrap;
  -ms-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.menu-item:not(:last-child) {
  border-bottom: 1px solid #eee;
}

header #top-menu .menu-item.active {
  background-color: var(--color-alert-bg);
  color: #fff;
  font-weight: 700;
  text-shadow: 1px 1px 1px rgb(30 30 30 / 80%);
}

.nav-btn {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-color: transparent;
  color: transparent;
}

main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* make sure the scroller does not overflow */
  overflow: hidden;
  overflow: clip;
}

#footer-nav {
  display: none;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-content: center;
  pointer-events: all;
  z-index: 99;
  opacity: 1;
  transition: opacity 1s;
}

.modal.hidden {
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s;
}

.modal__title {
  margin-bottom: 0.75rem;
  text-align: center;
  font-size: 1.05rem;
  font-weight: 400;
  font-family: var(--font-body-secondary);
  color: var(--color-text-highlight);
  text-transform: uppercase;
}

.modal__content {
  text-shadow: 1px 2px 2px rgb(0 0 0 / 65%);
  pointer-events: all;
  -ms-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.modal__btn {
  display: block;
  margin-inline: auto;
  margin-top: 1rem;
  padding: 0.5rem 2rem;
  color: var(--color-text-primary);
  border: 2px solid var(--color-text-primary);
  border-radius: 100rem;
  background-color: transparent;
}

.modal__btn-close:before {
  content: "OK";
  font-size: 1.05em;
  font-weight: 600;
}

.modal__button:active,
.modal__button:focus {
  background-color: transparent;
}

dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: transparent;
  color: var(--color-text-primary);
}

dialog::backdrop {
  /* Opera having trouble recognizing CSS variables here */
  background-color: rgba(10, 23, 49, 0.75);
  filter: blur(8px);
  pointer-events: none;
}

.accordion-section {
  border-top: 1px solid var(--color-input-border);
}

.accordion-section:last-of-type {
  border-bottom: 1px solid var(--color-input-border);
}

.accordion-section h4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.1rem 0.5rem 0.6rem;
  background-color: var(--color-bg-menu-sm);
}

.accordion-section h4:after {
  content: url("../assets/icons/expand_more_FILL0_wght400_GRAD0_opsz28.svg");
  width: 28px;
  height: 28px;
  color: var(--color-text-primary);
  /* invert color to white */
  filter: invert(1);
}

.accordion-section.expanded h4:after {
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.accordion-section:not(.expanded) > :not(h4) {
  height: 0;
  visibility: hidden;
  opacity: 0;
}

.accordion-section.expanded p {
  padding: 0.5rem 0.5rem 0.75rem;
  font-size: 1.05rem;
  line-height: 1.25em;
}

/********* GENERAL SECTION LAYOUT *********/

.content-scroller {
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 500vh;
  height: 500dvh;
  /* each section is 100% viewport high */
  overflow-y: hidden;
  transition: top 1s ease;
}

.content-scroller[data-section="0"] {
  top: 0;
}

.content-scroller[data-section="1"] {
  top: -100dvh;
}

.content-scroller[data-section="2"] {
  top: -200dvh;
}

.content-scroller[data-section="3"] {
  top: -300dvh;
}

.content-scroller[data-section="4"] {
  top: -400dvh;
}

.section {
  position: relative;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: transparent;
  overflow: hidden;
}

section > .section-bg {
  position: absolute;
  inset: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 0.15;
}

.section-title {
  font-size: 1.075rem;
  font-weight: 400;
  font-family: var(--font-body-primary);
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-text-highlight);
  text-shadow: 1px 2px 2px rgb(0 0 0 / 65%);
  -ms-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.section-subtitle,
.section .subtitle {
  line-height: 1.6;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: var(--font-body-primary);
  text-shadow: 1px 2px 2px rgb(0 0 0 / 65%);
  -ms-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.section-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0.6rem;
  overflow: hidden;
}

/***** CARDS *****/

.cardset {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  list-style: none;
}

.card {
  display: grid;
  place-content: center;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 0.35rem;
  -ms-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.card .card-wrapper {
  overflow: hidden;
}

/******** CONTENT ***********/

/***** SECTION 0 - HOME *****/
#section-home > .section-bg {
  background-image: url("../assets/bg/12.webp");
  opacity: 0.2;
}

#section-home .section-content {
  height: 100%;
  display: grid;
  place-content: center start;
}

#greeting {
  padding-block: 3rem 0.5rem;
  padding-inline: min(2ch, 10vw);
  font-size: 0.8rem;
  z-index: 1;
  -ms-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

#greeting-message {
  margin-bottom: 0.75rem;
  text-align: justify;
  font-size: 1.375em;
  font-weight: 400;
  font-family: var(--font-body-primary);
  color: var(--color-text-highlight);
  text-shadow: 1px 1px 1px rgb(0 0 0 / 60%);
  opacity: 0;
}

#greeting-motto {
  width: 10ch;
  margin-bottom: 1.25rem;
  font-size: 3.75em;
  line-height: 1.1;
  font-weight: 700;
  text-shadow: 2px 2px 2px rgb(0 0 0 / 70%);
  opacity: 0;
}

#greeting-subtitle {
  margin-bottom: 1.8rem;
  font-size: 1.45em;
  line-height: 1.4;
  text-shadow: 1px 2px 2px rgb(0 0 0 / 65%);
  opacity: 0;
}

#greeting .btn {
  opacity: 0;
}

#greeting .btn:active {
  -ms-transform: scale(0.95);
  -o-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

section.active #greeting > #greeting-message {
  opacity: 1;
  animation: sildeInFromTop 1.25s forwards;
}

section.active #greeting > #greeting-motto {
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  animation: sildeInFromLeft 2.25s forwards;
}

section.active #greeting-subtitle {
  opacity: 1;
  animation: sildeInFromBottom 1.75s forwards;
}

section.active #greeting .btn {
  opacity: 1;
  animation: FadeIn 2.5s linear both 400ms;
}

/***** SECTION 1 - SERVICES *****/
#section-services > .section-bg {
  background-image: url("../assets/bg/03.webp");
  opacity: 0.15;
}

#section-services .section-title {
  display: block;
  margin-block: 0.75rem 0.6rem;
  text-align: center;
  opacity: 0;
  transition: 350ms all ease-out 700ms;
}

#section-services.active .section-title {
  opacity: 1;
}

#section-services .section-title.hidden {
  display: none;
}

#section-services .section-content {
  height: 100%;
  padding-top: 6rem;
  -moz-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 2;
}

/* services list */
#section-services #services-list {
  display: block;
  width: 39ch;
  margin-inline: auto;
  list-style: none;
  border-radius: 0.25em;
  text-transform: uppercase;
  font-size: 0.85em;
  font-weight: 500;
  color: #fff;
  opacity: 0;
  transition: opacity 1.5s ease-out 0.5s;
}

#section-services #services-list.hidden {
  display: none;
}

#section-services.active #services-list {
  opacity: 1;
}

#section-services.active #services-list .service {
  display: flex;
  align-items: center;
}

/* section-services icons */
.service-icon-container {
  display: grid;
  place-content: center;
  height: 32px;
  width: 32px;
  margin-inline: 0 0.45rem;
  border: 1px solid #fff;
  border-radius: 50%;
}

#services-list > .service.active .service-icon-container {
  border-color: #444;
  border-width: 2px;
}

#services-list > .service.active svg {
  fill: #333;
}

#section-services .service {
  padding: 1em 0.75em;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background-color: rgba(255, 255, 255, 0.05);
  text-shadow: 1px 2px 2px rgb(0 0 0 / 65%);
  cursor: pointer;
}

#section-services .service:not(:last-child) {
  border-bottom: 0;
}

#section-services .service:last-child {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

#section-services .service:first-child {
  border-top-left-radius: 0.35em;
  border-top-right-radius: 0.35em;
}

#section-services .service.active {
  background-color: rgba(255, 255, 255, 0.75);
  color: #333;
  font-weight: 700;
  text-shadow: none;
  text-shadow: 1px 2px 2px rgb(255 255 255 / 45%);
  cursor: unset;
}

/* service description card header */
#section-services .service-name {
  position: relative;
  display: flex;
  align-items: center;
  padding-inline: 1.75rem 45px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  font-size: 0.95rem;
  font-weight: 700;
  text-transform: uppercase;
}

.service-details.active .service-name:after {
  content: url("../assets/icons/expand_less_FILL1_wght400_GRAD0_opsz32.svg");
  position: absolute;
  top: 8px;
  right: 8px;
  height: 32px;
  width: 32px;
  padding: 2px 0 0 0;
  margin: 0;
  display: grid;
  place-content: center;
  border: none;
  border-radius: 50%;
  text-align: center;
  color: var(--color-text-primary);
  background-color: var(--color-bg-light);
  text-shadow: none;
}

#section-services .service-name svg {
  display: none;
}

/* service description content */
#section-services .service-details {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: all 150ms ease-out;
}

#section-services .service-details.active {
  display: inline-block;
  margin-inline: auto;
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-radius: 0.35rem;
  background-color: var(--color-modal-bg);
  text-shadow: 1px 2px 2px rgb(0 0 0 / 65%);
  visibility: visible;
  opacity: 1;
  transition: opacity 2s ease-out 250ms;
}

#section-services.active:has(.service-details.active) .section-title {
  display: none;
}

#section-services .service-details.active .service-name > span:before {
  /* content: "•••"; */
  display: block;
  content: "OUR SERVICES:";
  line-height: 1.5;
  padding-top: 8px;
}

#section-services .service-description {
  padding: 0.75rem 0.75rem 0.5rem 1.75rem;
  list-style-type: circle;
}

#section-services .service-description > li {
  font-size: 0.95em;
  line-height: 1.3;
  margin-bottom: 0.6rem;
}

#section-services .service-description > li:has(+ li.md) {
  /* styles for the immediate sibling before li.md */
  margin-bottom: 0.25rem;
}

.service-name svg {
  height: 24px;
  width: 24px;
  margin-inline: 0.5em 0.35em;
}

/***** SECTION 2 - ABOUT *****/
#section-about > .section-bg {
  background-image: url("../assets/bg/01.webp");
  opacity: 0.15;
}

#section-about > .section-content {
  display: grid;
  place-content: center;
  height: 100vh;
  height: 100dvh;
  padding-block: 3.5rem 0.5rem;
  font-size: 0.95rem;
}

/* #about-facts */
#about-facts {
  margin-inline: 0.25rem;
  gap: 0.4rem;
}

.fact-card {
  place-content: start center;
  width: 100%;
  max-width: 230px;
  padding: 0.5rem;
  padding-bottom: 1rem;
  background-color: var(--color-card-light-bg);
  /* transition: all 200ms ease; */
}

.fact-card .card-face,
.fact-card .card-back {
  text-align: center;
  font-family: var(--font-body-secondary);
  text-shadow: 1px 2px 2px rgb(0 0 0 / 65%);
}

.fact-card .card-face {
  font-size: 0.925rem;
}

.fact-card .card-back {
  display: none;
}

.fact-card .card-face .card-title {
  margin-bottom: 0.5rem;
  font-size: 3.5rem;
  line-height: 3.5rem;
  font-family: var(--font-body-secondary);
  font-weight: 500;
  text-shadow: 2px 2px 2px rgba(47, 47, 47, 0.65);
}

.fact-card .card-face .card-subtitle {
  font-size: 1.15rem;
  font-weight: 400;
  line-height: 1.15;
  margin-bottom: 0.75rem;
}

.fact-card .card-back {
  font-size: 0.85rem;
  line-height: 1.35;
}

.fact-card .card-back p:nth-of-type(2) {
  margin-top: 0.5rem;
}

#section-about .section-title {
  margin-block: 1.5rem 1rem;
  opacity: 0;
  -ms-transform: scale(0.85);
  -o-transform: scale(0.85);
  -moz-transform: scale(0.85);
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
  transition: 750ms all ease-out 500ms;
}

#section-about.active .section-title {
  opacity: 1;
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* clients and projects */
#projects-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  max-width: 800px;
  margin: auto;
  list-style: none;
  padding: 0;
}

.card.project-card {
  width: 46%;
  aspect-ratio: 25/15;
  max-width: 230px;
  overflow: hidden;
  border: 1px solid rgba(214, 214, 214, 0.15);
  background-color: rgb(214, 214, 214);
  -webkit-box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
}

.card.project-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

/***** SECTION 3 - WHY US / FEATURES *****/
#section-features > .section-bg {
  background-image: url("../assets/bg/02.webp");
  opacity: 0.1;
}

#section-features .section-content {
  display: grid;
  place-content: center;
  padding-block: 3.5rem 0.5rem;
  padding-inline: 0.75rem;
  z-index: 2;
  -moz-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#section-features .section-title {
  max-width: 32ch;
  margin-inline: auto;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.05em;
  line-height: 1.2;
  letter-spacing: 0.02em;
  opacity: 0;
  -ms-transform: scale(0.85);
  -o-transform: scale(0.85);
  -moz-transform: scale(0.85);
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
  transition: all 750ms ease-out 500ms;
}

#section-features.active .section-title {
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

#section-features .feature {
  display: flex;
  gap: 0.5em;
  margin-bottom: 0.65em;
  font-size: 0.9rem;
  line-height: 1.1;
  opacity: 0;
  transition: all 850ms ease-out 500ms;
}

#section-features .feature img {
  display: block;
  height: 4.5em;
  aspect-ratio: 1.2;
  color-scheme: only light;
}

#section-features .feature .feature-text {
  display: block;
  width: 100%;
  padding-bottom: 0.5em;
  border-bottom: 1px solid rgba(214, 214, 214, 0.4);
}

#section-features .feature-title {
  margin-bottom: 0.25em;
  font-size: 1.1em;
  font-weight: 600;
  line-height: 1.2;
}

#section-features .feature-left {
  -ms-transform: translate3d(-30%, 0, 0);
  -o-transform: translate3d(-30%, 0, 0);
  -moz-transform: translate3d(-30%, 0, 0);
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0);
}

#section-features .feature-right {
  flex-direction: row-reverse;
  -ms-transform: translate3d(30%, 0, 0);
  -o-transform: translate3d(30%, 0, 0);
  -moz-transform: translate3d(30%, 0, 0);
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
}

#section-features .feature-right .feature-text {
  text-align: end;
}

#section-features.active .feature {
  -ms-transform: scale(1) translate(0, 0);
  -o-transform: scale(1) translate(0, 0);
  -moz-transform: scale(1) translate(0, 0);
  -webkit-transform: scale(1) translate(0, 0);
  transform: scale(1) translate(0, 0);
  text-shadow: 1px 2px 2px rgb(0 0 0 / 65%) !important;
  opacity: 1;
}

#section-features .logo-container {
  display: none;
}

/********* SECTION 4 - CONTACT US *********/
#section-contacts > .section-bg {
  background-image: url("../assets/bg/11.webp");
  opacity: 0.2;
}

#section-contacts.active {
  /* avoid viewport resizing (dvh) when android on-screen keyboard is shown */
  position: fixed;
  bottom: 0;
  z-index: 9;
}

#section-contacts .section-content {
  display: grid;
  place-content: center stretch;
  padding-block: 3.25rem 1.75rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1.2s ease 500ms;
}

#section-contacts.active .section-content {
  opacity: 1;
  visibility: visible;
}

#section-contacts.active .section-content.hidden {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
}

#section-contacts .content-wrapper {
  padding-inline: 0.15rem;
}

#section-contacts .section-title {
  text-align: start;
  font-size: 1.05rem;
}

#contact-page-header {
  grid-area: 2 / 1 / 3 / 2;
  padding: 0.35rem;
  padding-bottom: 0.5rem;
}

#contact-form-wrapper {
  grid-area: 3 / 1 / 4 / 2;
  -webkit-user-drag: none;
}

.input-container {
  position: relative;
  width: 100%;
  margin-bottom: 0.7rem;
  border: 1px solid var(--color-input-border);
  outline: 2px solid transparent;
  border-radius: 0.2rem;
  background-color: var(--color-input-bg);
}

.input-container:focus-within {
  outline-color: rgba(255, 255, 255, 0.75);
}

#contact-form input,
#contact-form textarea {
  width: calc(100% - 2.25rem);
  margin-left: 2rem;
  line-height: 2.5rem;
  font-size: 1rem;
  font-family: var(--font-body-primary);
  background-color: transparent;
  color: currentColor;
}

#contact-form textarea {
  padding-block: 0.55rem 0.15rem;
  line-height: 1.25;
  height: 4.45rem;
}

#contact-form .input-container:has(input.invalid) {
  border-color: var(--color-input-border-error);
}

.input-icon {
  position: absolute;
  top: 0.7rem;
  left: 0.35rem;
}

.input-icon:has(~ textarea) {
  top: 0.5rem;
}

/* Disable autocomplete background highlighting */
#contact-form .contact-input:-webkit-autofill,
#contact-form .contact-input:-webkit-autofill:hover,
#contact-form .contact-input:-webkit-autofill:focus,
#contact-form .contact-input:-webkit-autofill:active,
#contact-form .input-container:has(.contact-input:-webkit-autofill),
#contact-form .input-container:has(.contact-input:-webkit-autofill:hover),
#contact-form .input-container:has(.contact-input:-webkit-autofill:focus),
#contact-form .input-container:has(.contact-input:-webkit-autofill:active) {
  -webkit-box-shadow: 0 0 0 30px hsl(220, 32%, 22%) inset !important;
  -webkit-text-fill-color: var(--color-text-primary);
}

#contact-form .form-group {
  position: relative;
  -webkit-user-drag: none;
}

#contact-form .btn {
  display: block;
  margin-inline: auto 0;
}

#contact-form .btn:focus-visible {
  outline: solid 2px var(--color-text-primary);
}

#contact-form-dialog {
  width: 100%;
}

#contact-form-dialog .content-wrapper {
  min-height: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.6rem 0.7rem;
  border: 1px solid white;
  background-color: var(--color-alert-bg);
  color: var(--color-text-primary);
  text-shadow: 1px 1px 1px rgb(0 0 0 / 65%);
  box-shadow: 1px 1px 3px rgb(0 0 0 / 65%);
}

#contact-form-dialog .dialog-title {
  margin-bottom: 1.25rem;
  font-size: 1.15rem;
  font-weight: 500;
}

#contact-form-dialog.error .btn--dlg-circle {
  background-color: rgba(164, 45, 32, 0.67);
}

#contact-form-dialog.error .btn--dlg-circle:before {
  content: "\2715";
}

#contact-form-dialog.success .btn--dlg-circle {
  background-color: var(--color-alert-success);
  border: 1px solid var(--color-input-border);
}

#contact-form-dialog.success .btn--dlg-circle:before {
  content: "\2713";
  font-size: 1.35rem;
  font-weight: 500;
}

#contact-form-dialog .dialog-message {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.45;
}

#contact-form-dialog .dialog-message b {
  display: none;
}

#contact-form-dialog ul {
  list-style-position: inside;
  list-style-type: disclosure-closed;
}

#contact-locations {
  position: relative;
}

#contact-locations-header {
  margin-bottom: 0.25rem;
  font-size: 1.05rem;
  font-weight: 400;
  font-family: var(--font-body-primary);
  text-transform: uppercase;
  color: var(--color-text-highlight);
  text-shadow: 1px 2px 2px rgb(0 0 0 / 65%);
  -moz-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#contact-warning,
.contact-location {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.5rem;
  padding: 0.5rem 0.75rem 0.5rem;
  margin-bottom: 0.7rem;
  font-size: 0.95em;
  line-height: 1.35;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 65%);
}

#contact-warning {
  align-items: center;
  margin-bottom: 0;
  line-height: 1.35;
  cursor: pointer;
}

#contact-warning.expanded {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -2rem;
  display: grid;
  place-content: center stretch;
  /* padding-inline: 1rem 2.75rem; */
  padding-inline: 1rem 2.75rem;
  font-size: 1rem;
  background-color: rgb(57, 76, 113);
}

#contact-warning.expanded p:not(:last-child) {
  margin-bottom: 0.5rem;
}

#contact-warning:not(.expanded) .exp-hidden,
#contact-warning.expanded .exp-visible {
  display: block;
}

#contact-warning:not(.expanded) .exp-visible,
#contact-warning.expanded .exp-hidden {
  display: none;
}

#contact-warning-icon {
  height: 21px;
  width: 21px;
}

/* Privacy & Terms */
#section-contacts #terms {
  place-content: start center;
  padding: 5.5rem 1rem 1rem;
}

#section-contacts #terms ul {
  list-style-position: inside;
  margin-left: 0.5rem;
  line-height: 1.35;
}

#section-contacts.expanded #terms ul {
  margin-bottom: 1.5rem;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 0.5rem;
  line-height: 1.75;
  text-align: center;
  z-index: 50;
  font-family: var(--font-body-secondary);
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.05ch;
}

.footer #footer__corp {
  -moz-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.footer #footer__copyright {
  font-size: 0.85rem;
  font-family: var(--font-body-secondary);
}

.footer #footer__terms {
  cursor: pointer;
}

/********** ********** ********** ********** ********** ********** **********/
/********** ********** ********** RESPONSIVE ********** ********** **********/
/********** ********** ********** ********** ********** ********** **********/

/* older mobile phones like iPhone 6/7/8 */
@media screen and (width < 400px) and (height <=675px) {
  /***** SECTION 0 - HOME *****/
  #greeting {
    font-size: 0.7rem;
  }

  /***** SECTION 1 - SERVICES *****/
  #section-services .section-content {
    padding-top: 3.75rem;
  }

  #section-services .section-title {
    font-size: 0.95rem;
  }

  #section-services .service-name,
  #section-services .service-description > li {
    font-size: 0.95rem;
  }

  /***** SECTION 2 - ABOUT *****/
  .fact-card {
    padding: 0.35rem 0.35rem 0.5rem 0.35rem;
  }

  .fact-card .card-face {
    font-size: 0.875rem;
  }

  .fact-card .card-face .card-title {
    margin-bottom: 0;
    font-size: 3.25rem;
  }

  .fact-card .card-face .card-subtitle {
    font-size: 1.025rem;
  }

  #section-about.active .section-title {
    margin-block: 1.25rem 0.5rem;
    font-size: 0.975rem;
  }

  #projects-list {
    gap: 0.6rem;
  }

  .card.project-card {
    width: 47%;
  }

  /***** SECTION 3 - WHY US / FEATURES *****/
  #section-features .section-title {
    font-size: 0.975rem;
  }

  #section-features .feature {
    font-size: 0.85rem;
  }

  /********* SECTION 4 - CONTACT US *********/
  #section-contacts .section-content,
  #contact-form input,
  #contact-form textarea {
    font-size: 0.95rem;
  }

  #contact-page-header {
    display: none;
  }

  #contact-locations-header {
    font-size: 0.975rem;
  }

  #contact-warning.expanded {
    bottom: unset;
  }

  .modal__title,
  .accordion-section.expanded p {
    font-size: 0.975rem;
  }

  /* Privacy & Terms */
  #section-contacts #terms {
    padding-top: 4rem;
  }
}

/******* larger phones and phablets ********/
@media screen and (width >=600px) {
  .sm {
    display: none;
  }

  .md {
    display: unset;
  }

  li.md {
    display: list-item;
  }

  header #top-nav {
    width: 26ch;
  }

  .section .section-title {
    font-size: 1.075rem;
    font-weight: 500;
    font-family: var(--font-body-primary);
    color: var(--color-text-highlight);
    text-shadow: 1px 1px 1px rgb(0 0 0 / 60%);
    letter-spacing: unset;
  }

  /********* SECTION 0 - HOME ***************/
  #greeting {
    width: 60ch;
    max-inline-size: 80%;
    margin-left: min(2rem, 10vw);
  }

  /********** SECTION 1 - SERVICES ***********/
  #section-services .section-content {
    padding-inline: 0.75rem;
  }

  #section-services .section-title {
    margin-inline: 0;
    text-align: center;
  }

  #section-services #services-list {
    width: 380px;
  }

  /****** SECTION 3 - WHY US / FEATURES ******/
  #section-features .section-content {
    padding-top: 6rem;
    place-content: start center;
  }

  #section-features .section-title,
  #section-features .feature {
    max-width: 480px;
    margin-inline: auto;
    margin-bottom: 1.5rem;
    letter-spacing: unset;
  }

  /********* SECTION 4 - CONTACT US *********/
  #section-contacts .section-content {
    place-content: start;
    padding: 6rem 1rem 2rem 2rem;
  }

  #section-contacts .section-title {
    margin-bottom: 0.25rem;
  }

  #contact-form-dialog {
    width: 420px;
  }

  #contact-locations {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0.75rem;
  }

  .contact-location {
    font-size: 1em;
  }

  #contact-warning {
    grid-area: 2 / 1 / 3 / 3;
    font-size: 1em;
    line-height: 1.35;
  }

  #contact-warning.expanded {
    inset: unset;
    display: block;
    background-color: rgba(61, 82, 124, 0.75);
  }
}

/********** ********** ********** TABLET 768 ********** ********** **********/
@media screen and (width >=768px) {
  /* NAVIGATION */
  header {
    padding: 0.5rem 0.75rem 0 0.75rem;
  }

  header #main-logo {
    font-size: 0.65rem;
  }

  #footer-nav {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 50;
  }

  #footer-nav > .nav-btn {
    height: 48px;
    width: 48px;
    margin-bottom: 0.25rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='40' width='40' viewBox='0 -960 960 960' %3E%3Cpath d='m480-359.514 162.384-162.383-35.999-34.999L480-430.512 353.615-556.896l-35.999 34.999L480-359.514Zm.067 259.513q-78.426 0-147.666-29.92t-120.887-81.544q-51.647-51.624-81.58-120.833-29.933-69.21-29.933-147.635 0-78.836 29.92-148.204 29.92-69.369 81.544-120.682 51.624-51.314 120.833-81.247 69.21-29.933 147.635-29.933 78.836 0 148.204 29.92 69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173 0 78.426-29.92 147.666t-81.21 120.887q-51.291 51.647-120.629 81.58-69.337 29.933-148.173 29.933ZM480-150.256q137.795 0 233.769-96.18Q809.744-342.615 809.744-480q0-137.795-95.975-233.769Q617.795-809.744 480-809.744q-137.385 0-233.564 95.975-96.18 95.974-96.18 233.769 0 137.385 96.18 233.564 96.179 96.18 233.564 96.18ZM480-480Z' fill='%23eeeeee'/%3E%3C/svg%3E");
    opacity: 0.9;
  }

  #footer-nav:hover > .nav-btn {
    opacity: 0.9;
  }

  /* Transitions for active section background size and opacity */
  section.active > .section-bg {
    animation: fadeInBg 2.75s cubic-bezier(0.3, 0, 0.7, 1) both;
  }

  section .section-title {
    font-size: 1.075rem;
    letter-spacing: unset;
  }

  /***** SECTION 0 - HOME ****/
  #greeting #greeting-message {
    margin-block: 1.45rem;
    font-size: 1.15rem;
    font-weight: 500;
  }

  #greeting-motto {
    margin-bottom: 3rem;
    /* font-size: 3.5em; */
    /* line-height: 1; */
  }

  #greeting-subtitle {
    margin-bottom: 1.75rem;
    font-size: 1.4em;
    line-height: 1.45;
  }

  #greeting .btn {
    font-size: 1.2em;
  }

  header #top-menu .menu-item {
    padding-block: 1rem;
    font-size: 1.05rem;
  }

  /***** SECTION 1 - SERVICES *****/
  #section-services .section-content {
    padding: 5rem 0.5rem 0 1.5rem;
    display: grid;
    grid-template-rows: max-content;
    grid-template-columns: auto 1fr;
    grid-auto-flow: row;
    gap: 0.25em;
  }

  /* services list */
  #section-services .section-title {
    grid-area: 1 / 1 / 2 / 2;
    padding-left: 0.75rem;
    margin-block: 0 0.5rem;
    text-align: left;
    font-size: 1.09rem;
  }

  #section-services #services-list {
    grid-area: 2 / 1 / 3 / 2;
    width: 39ch;
  }

  #services-list > .service:hover .service-icon-container {
    border: 1px solid #f39200;
  }

  #services-list > .service:hover svg {
    fill: #f39200;
  }

  #services-list > .service.active .service-icon-container {
    border-color: #444;
    border-width: 2px;
  }

  #services-list > .service.active svg {
    fill: #333;
  }

  #section-services .service:not(.active):hover {
    color: var(--color-text-highlight);
  }

  #section-services .service-name {
    height: auto;
    padding-inline: 0.3rem 0;
    font-size: 1.25em;
    font-weight: 600;
    outline: none;
    background: none;
  }

  .service-details.active .service-name:after {
    display: none;
  }

  #section-services .service-name svg {
    display: inline-block;
    height: 28px;
    width: 28px;
    margin-inline: 0 0.35rem;
    vertical-align: middle;
    fill: #cccccc;
  }

  /* service description content */
  #section-services .service-details {
    position: relative;
    left: unset;
    right: unset;
    display: grid;
    grid-area: 2 / 2 / 3 / 3;
    height: -moz-fit-content;
    height: fit-content;
    padding-inline: 2rem 1rem;
    border-radius: 0.5rem;
    -ms-transform: scale(0.85) translate3d(0, -32px, 0);
    -o-transform: scale(0.85) translate3d(0, -32px, 0);
    -moz-transform: scale(0.85) translate3d(0, -32px, 0);
    -webkit-transform: scale(0.85) translate3d(0, -32px, 0);
    transform: scale(0.85) translate3d(0, -32px, 0);
  }

  #section-services .service-details.active {
    margin: unset;
    padding-top: 0.35rem;
    border: none;
    background-color: unset;
    -ms-transform: scale(1) translate3d(0, 0, 0);
    -o-transform: scale(1) translate3d(0, 0, 0);
    -moz-transform: scale(1) translate3d(0, 0, 0);
    -webkit-transform: scale(1) translate3d(0, 0, 0);
    transform: scale(1) translate3d(0, 0, 0);
    transition:
      transform 700ms ease-out 250ms,
      opacity 2s ease-out 250ms;
  }

  #section-services .service-details.active .service-name > span:before {
    display: none;
  }

  #section-services .service-description {
    padding: 0.75rem 0.5rem 0.25rem;
    list-style-type: disc;
  }

  #section-services .service-description > li {
    font-size: 1rem;
    line-height: 1.4;
    margin-bottom: 1rem;
  }

  #btn-service-description-prev,
  #btn-service-description-next {
    display: none;
  }

  /***** SECTION 2 - ABOUT *****/
  #section-about > .section-content {
    padding-block: 4.25rem 3.25rem;
  }

  #about-facts {
    width: 100%;
    max-width: 800px;
    gap: 1rem;
    margin: auto;
  }

  .fact-card .card-face {
    padding-block: 0.5rem 1.5rem;
    font-size: 1.15em;
  }

  .fact-card .card-face .card-title {
    font-size: 4.75rem;
    line-height: 1;
    text-shadow: 1px 2px 2px rgba(47, 47, 47, 0.65);
  }

  .fact-card .card-face .card-subtitle {
    font-size: 1.4rem;
    margin-bottom: 1.2rem;
  }

  #about-projects .section-title {
    margin-block: 2.5rem 1rem;
    font-size: 1.095rem;
  }

  .card.project-card img {
    transition: all 250ms ease;
  }

  .card.project-card:hover img {
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  /***** SECTION 3 - WHY US / FEATURES *****/
  #section-features .section-content {
    display: grid;
    grid-template-columns: 1fr auto auto 1fr;
    margin-inline: auto;
    padding-block: 6rem 3.15rem;
    gap: 0.5em;
  }

  #section-features .feature {
    flex-direction: column;
    max-width: 33ch;
  }

  #section-features .feature-left {
    justify-self: end;
  }

  #section-features .feature-right {
    justify-self: start;
  }

  #section-features .feature-right .feature-text {
    text-align: start;
  }

  #section-features .feature img {
    height: 5em;
    aspect-ratio: 1;
  }

  #section-features .feature-left img {
    margin-left: auto;
  }

  #section-features .feature-right img {
    margin-right: auto;
  }

  #section-features .feature-title {
    margin-bottom: 0.5em;
    font-size: 1.05rem;
    font-weight: 600;
    line-height: 1.2;
  }

  /* positioning of each grid cell: row-start / col-start / row-end / col-end */
  #section-features .section-title {
    grid-area: 1 / 1 / 2 / 5;
    max-width: 46ch;
    margin-inline: auto;
    margin-bottom: 1.1rem;
    text-align: center;
    font-size: 1.1rem;
    -ms-transform: scale(0.75) translate3d(0, -0.5em, 0);
    -o-transform: scale(0.75) translate3d(0, -0.5em, 0);
    -moz-transform: scale(0.75) translate3d(0, -0.5em, 0);
    -webkit-transform: scale(0.75) translate3d(0, -0.5em, 0);
    transform: scale(0.75) translate3d(0, -0.5em, 0);
    opacity: 0.3;
    transition: all 1.5s ease-in;
  }

  .feature#f1 {
    grid-area: 2 / 1 / 3 / 2;
    text-align: right;
    opacity: 0;
    -ms-transform: scale(0.9) translate3d(-10px, -10px, 0);
    -o-transform: scale(0.9) translate3d(-10px, -10px, 0);
    -moz-transform: scale(0.9) translate3d(-10px, -10px, 0);
    -webkit-transform: scale(0.9) translate3d(-10px, -10px, 0);
    transform: scale(0.9) translate3d(-10px, -10px, 0);
    transition: all 750ms ease-out 500ms;
  }

  .feature#f2 {
    grid-area: 2 / 4 / 3 / 5;
    opacity: 0;
    -ms-transform: scale(0.9) translate3d(10px, -10px, 0);
    -o-transform: scale(0.9) translate3d(10px, -10px, 0);
    -moz-transform: scale(0.9) translate3d(10px, -10px, 0);
    -webkit-transform: scale(0.9) translate3d(10px, -10px, 0);
    transform: scale(0.9) translate3d(10px, -10px, 0);
    transition: all 750ms ease-out 1.3s;
  }

  .feature#f3 {
    grid-area: 3 / 1 / 4 / 2;
    text-align: right;
    opacity: 0;
    -ms-transform: scale(0.9) translate3d(-10px, 10px, 0);
    -o-transform: scale(0.9) translate3d(-10px, 10px, 0);
    -moz-transform: scale(0.9) translate3d(-10px, 10px, 0);
    -webkit-transform: scale(0.9) translate3d(-10px, 10px, 0);
    transform: scale(0.9) translate3d(-10px, 10px, 0);
    transition: all 750ms ease-out 1.7s;
  }

  .feature#f4 {
    grid-area: 3 / 4 / 4 / 5;
    opacity: 0;
    -ms-transform: scale(0.9) translate3d(10px, 10px, 0);
    -o-transform: scale(0.9) translate3d(10px, 10px, 0);
    -moz-transform: scale(0.9) translate3d(10px, 10px, 0);
    -webkit-transform: scale(0.9) translate3d(10px, 10px, 0);
    transform: scale(0.9) translate3d(10px, 10px, 0);
    transition: all 750ms ease-out 900ms;
  }

  .feature#f5 {
    grid-area: 4 / 1 / 5 / 3;
    margin-right: 2rem;
    text-align: right;
    opacity: 0;
    -ms-transform: scale(0.9) translate3d(-10px, 10px, 0);
    -o-transform: scale(0.9) translate3d(-10px, 10px, 0);
    -moz-transform: scale(0.9) translate3d(-10px, 10px, 0);
    -webkit-transform: scale(0.9) translate3d(-10px, 10px, 0);
    transform: scale(0.9) translate3d(-10px, 10px, 0);
    transition: all 750ms ease-out 2.5s;
  }

  .feature#f6 {
    grid-area: 4 / 3 / 5 / 5;
    margin-left: 2rem;
    opacity: 0;
    -ms-transform: scale(0.9) translate3d(10px, 10px, 0);
    -o-transform: scale(0.9) translate3d(10px, 10px, 0);
    -moz-transform: scale(0.9) translate3d(10px, 10px, 0);
    -webkit-transform: scale(0.9) translate3d(10px, 10px, 0);
    transform: scale(0.9) translate3d(10px, 10px, 0);
    transition: all 750ms ease-out 2.1s;
  }

  #section-features.active .section-title {
    -ms-transform: scale(1) translateY(0);
    -o-transform: scale(1) translateY(0);
    -moz-transform: scale(1) translateY(0);
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    opacity: 1;
  }

  #section-features.active .feature {
    -ms-transform: scale(1) translate3d(0, 0, 0);
    -o-transform: scale(1) translate3d(0, 0, 0);
    -moz-transform: scale(1) translate3d(0, 0, 0);
    -webkit-transform: scale(1) translate3d(0, 0, 0);
    transform: scale(1) translate3d(0, 0, 0);
    text-shadow: 1px 2px 2px rgb(0 0 0 / 65%) !important;
  }

  #section-features .logo-container {
    display: grid;
    grid-area: 2 / 2 / 4 / 4;
    justify-self: center;
    width: 33vw;
    max-width: min(45vw, 45vh);
    padding: 0.75em;
  }

  #section-features .logo__circle {
    position: relative;
    /* center in the section wrapper */
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    box-shadow: inset 0 0 20px 10px rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    color: var(--color-button-bg);
    opacity: 0;
    transition: opacity 2s ease-in 500ms;
  }

  #section-features.active .logo__circle {
    opacity: 1;
  }

  #section-features .logo__img {
    width: 45%;
    aspect-ratio: 1;
    pointer-events: none;
  }

  #section-features .logo__brand {
    font-size: 1.75em;
    line-height: 1;
    font-weight: 400;
    font-family: var(--font-logo);
    white-space: nowrap;
    text-shadow: 1px 1px 1px rgb(0 0 0 / 60%);
  }

  /********* SECTION 4 - CONTACT US *********/
  #section-contacts .section-content {
    padding-left: 3.5rem;
  }

  #section-contacts .section-title {
    margin-bottom: 0.15rem;
    font-size: 1.085rem;
    letter-spacing: 0.05ch;
  }

  #section-contacts .content-wrapper {
    width: 100%;
    max-width: 700px;
    padding: 0;
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 380px minmax(300px, 1fr);
    column-gap: 1.5rem;
  }

  #contact-page-header {
    grid-area: 1 / 1 / 2 / -1;
    padding-bottom: 1.5rem;
  }

  #contact-form-wrapper {
    grid-area: 3 / 1 / 4 / 2;
  }

  #contact-form {
    margin-bottom: 1rem;
  }

  /* #contact-form  */
  .input-container {
    position: relative;
    width: 100%;
    margin-bottom: 0.85rem;
    border: 1px solid var(--color-input-border);
    outline: 2px solid transparent;
    border-radius: 0.2rem;
    background-color: var(--color-input-bg);
  }

  .input-container:focus-within {
    outline-color: rgba(255, 255, 255, 0.75);
  }

  #contact-form input,
  #contact-form textarea {
    width: calc(100% - 2.25rem);
    margin-left: 2rem;
    line-height: 2.5rem;
    font-size: 1rem;
    font-family: var(--font-body-secondary);
    background-color: transparent;
    color: currentColor;
  }

  #contact-form textarea {
    padding-block: 0.55rem;
    line-height: 1.35;
    height: unset;
  }

  #contact-form .input-container:has(input.invalid) {
    border-color: var(--color-input-border-error);
  }

  .input-icon {
    position: absolute;
    top: 0.7rem;
    left: 0.35rem;
  }

  .input-icon:has(~ textarea) {
    top: 0.5rem;
  }

  #contact-form .form-group {
    position: relative;
  }

  #contact-form .btn {
    display: inline;
    margin: unset;
  }

  #contact-form .btn:focus-visible {
    outline: solid 2px var(--color-text-primary);
  }

  #contact-form-dialog .dialog-message {
    font-size: 1.05rem;
  }

  #contact-locations-header {
    grid-area: 2 / 2 / 3 / 3;
    margin: auto auto 0.5rem 0.7rem;
    align-self: end;
    line-height: 1;
    font-size: 1.05rem;
    font-family: var(--font-body-secondary);
    letter-spacing: unset;
    text-transform: none;
    color: currentColor;
  }

  #contact-locations {
    grid-area: 3 / 2 / 4 / 3;
    display: block;
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 0.5rem;
    line-height: 1.75;
    text-align: center;
    z-index: 10;
    font-family: var(--font-body-secondary);
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 0.05ch;
  }

  .footer #footer__terms {
    cursor: pointer;
  }

  .card__warning,
  #contact-warning {
    max-width: unset;
  }

  /* Privacy & Terms Modal */
  #section-contacts #terms {
    place-content: center;
    padding-block: unset;
    font-size: 0.975rem;
  }

  #section-contacts #terms .modal__content {
    position: relative;
    max-width: 800px;
    padding: 1rem;
    border: 2px solid var(--color-input-border);
    border-radius: 0.5rem;
    background-color: var(--color-modal-bg);
  }

  .accordion-section:first-of-type {
    border-top: none;
  }

  .accordion-section:last-of-type {
    border-bottom: none;
  }

  #section-contacts #terms h4 {
    padding: 0.5rem 0 0.35rem 0;
    font-size: 1.15em;
    background-color: unset;
  }

  #section-contacts #terms h4:after {
    display: none;
  }

  #section-contacts #terms p,
  #section-contacts #terms ul {
    height: initial;
    padding: 0;
    margin-bottom: 0.75rem;
    font-size: 1em;
    visibility: visible;
    opacity: 1;
  }

  .modal__btn {
    position: absolute;
    top: 0.5rem;
    right: 0.6rem;
    height: 32px;
    width: 32px;
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 50%;
    text-align: center;
    background-color: var(--color-bg-light);
    color: var(--color-text-primary);
    cursor: pointer;
  }

  .modal__btn-close:before {
    content: "\2715";
    display: grid;
    place-content: center;
    height: 100%;
    width: 100%;
    font-size: 1.4em;
    font-weight: 300;
    font-family: var(--font-body-secondary);
    color: currentColor;
  }
}

/********** ********** ******** DESKTOP 1024 ******** ********** **********/
@media screen and (width >=1024px) {
  /* NAVIGATION */
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: var(--content-width-xl);
    margin-inline: auto;
    padding: 0.25em 0.5em;
  }

  header #main-logo {
    font-size: 0.8rem;
  }

  header #main-logo:hover {
    opacity: 0.75;
    cursor: pointer;
  }

  #menu-hamburger,
  #menu-close {
    display: none;
  }

  header #top-nav {
    position: relative;
    width: unset;
    max-width: unset;
    line-height: 100%;
    overflow: initial;
    border: none;
    background-color: transparent;
    -ms-transform: none;
    -o-transform: none;
    -moz-transform: none;
    -webkit-transform: none;
    transform: none;
    transition: none;
  }

  header #top-nav.active {
    border: none;
    border-radius: unset;
    -ms-transform: none;
    -o-transform: none;
    -moz-transform: none;
    -webkit-transform: none;
    transform: none;
    transition: none;
  }

  header #top-menu {
    flex-direction: row;
    padding: 0;
  }

  header #top-menu .menu-item {
    padding: 0.4em 1.35em;
    margin-inline: 3px 0;
    line-height: 1.1;
    border: none;
    outline: 1px solid transparent;
    font-size: 0.97rem;
    font-weight: 600;
    letter-spacing: 0.11ch;
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  header #top-menu .menu-item.active {
    outline: 1px solid var(--color-button-bg);
    background-color: var(--color-button-bg);
    text-shadow: none;
  }

  header #top-menu .menu-item:not(.active):hover {
    outline: 1px solid var(--color-text-primary);
  }

  #btn-service-description-prev,
  #btn-service-description-next {
    display: none;
  }

  #footer-nav > .nav-btn {
    cursor: pointer;
    transition: transform 250ms ease;
  }

  #footer-nav:hover > .nav-btn {
    -ms-transform: translate3d(0, 0.35rem, 0);
    -o-transform: translate3d(0, 0.35rem, 0);
    -moz-transform: translate3d(0, 0.35rem, 0);
    -webkit-transform: translate3d(0, 0.35rem, 0);
    transform: translate3d(0, 0.35rem, 0);
  }

  /* GENERAL SECTION LAYOUT */

  .content-scroller {
    flex-direction: row;
    height: 100vh;
    height: 100dvh;
    /* each section 100% viewport wide */
    width: 500%;
    transition: left 1s ease;
  }

  .content-scroller[data-section] {
    top: 0;
  }

  .content-scroller[data-section="0"] {
    left: 0;
  }

  .content-scroller[data-section="1"] {
    left: -100vw;
  }

  .content-scroller[data-section="2"] {
    left: -200vw;
  }

  .content-scroller[data-section="3"] {
    left: -300vw;
  }

  .content-scroller[data-section="4"] {
    left: -400vw;
  }

  .section .section-content {
    max-width: var(--content-width-xl);
    margin-inline: auto;
  }

  /********* SECTION 0 - HOME ***************/
  #section-home .section-content {
    font-size: 1rem;
    padding-inline: 1.5em;
  }

  #greeting {
    /* width: 50ch; */
    padding-inline: 0.9rem;
    font-size: 1.25rem;
  }

  #greeting #greeting-message {
    font-size: 1em;
  }

  #greeting-motto {
    margin-bottom: 1.35rem;
    line-height: 1;
    font-size: 3.5em;
  }

  #greeting-subtitle {
    margin-bottom: 2rem;
    font-size: 0.9em;
    line-height: 1.5;
  }

  #greeting .btn {
    font-size: 0.8em;
  }

  /********* SECTION 1 - SERVICES ***********/

  #section-services .section-content {
    padding-top: max(15vh, 6rem);
    /* padding-inline: min(10vw, 3rem) 1rem; */
    padding-inline: 4.25rem 1rem;
    gap: 0.25em 2em;
    font-size: 1.05rem;
  }

  /* services list */
  #section-services .section-title {
    display: none;
  }

  #section-services #services-list {
    width: 41ch;
  }

  /* service description header */
  #section-services .service-name {
    font-size: 1.35em;
  }

  .service-name svg {
    height: 30px;
    width: 30px;
  }

  /* service description content */
  #section-services .service-details {
    max-width: min(calc(90vw - 41ch), 800px);
  }

  /********** SECTION 2 - ABOUT ***************/
  #section-about > .section-content {
    padding: 4.5rem 1rem 3.15rem;
    font-size: 0.95rem;
  }

  /* #about-facts */
  #about-facts {
    gap: 1rem;
    margin: 0;
  }

  .fact-card {
    width: 250px;
    height: 270px;
    padding: 1rem;
    transition: all 200ms ease;
  }

  .fact-card:hover {
    place-content: center;
    background-color: var(--color-card-light-hover-bg);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  .fact-card:hover .card-face {
    display: none;
  }

  .fact-card:hover .card-back {
    display: block;
  }

  .fact-card .card-face {
    font-size: 1.2em;
  }

  .fact-card .card-face .card-title {
    font-size: 5rem;
    line-height: 5rem;
  }

  .fact-card .card-face .card-subtitle {
    font-size: 1.45em;
    line-height: 1.05;
    margin-bottom: 1.5rem;
  }

  .fact-card .card-back {
    font-size: 0.85rem;
  }

  .fact-card .card-back p {
    font-size: 0.95rem;
    text-align: left;
  }

  .fact-card .card-back p:nth-of-type(2) {
    margin-top: 0.75rem;
  }

  #about-projects .section-title {
    font-size: 1.175rem;
  }

  /***** SECTION 3 - WHY US / FEATURES *****/

  #section-features .section-title {
    margin-bottom: 1.75rem;
    font-size: 1.175rem;
  }

  #section-features .feature {
    font-size: 1rem;
  }

  #section-features .feature#f5 {
    margin-right: 2.75rem;
  }

  #section-features .feature#f6 {
    margin-left: 2.75rem;
  }

  /********* SECTION 4 - CONTACT US *********/

  #section-contacts.active {
    position: relative;
    bottom: unset;
    z-index: unset;
  }

  #section-contacts .section-content {
    justify-self: start;
    padding-top: 7.5rem;
    padding-left: 4rem;
  }

  #section-contacts .content-wrapper {
    grid-template-columns: 380px minmax(320px, 1fr);
  }

  #section-contacts .section-title {
    margin-bottom: 0.25rem;
    font-size: 1.16rem;
  }

  #section-contacts .btn {
    font-size: 0.975rem;
  }

  /* Handle 'Automatic dark mode' browser settings	*/
  :root {
    color-scheme: only light !important;
  }
}

@media screen and (width >=1368px) {
  /***** SECTION 3 - WHY US / FEATURES *****/

  #section-features .feature-right {
    margin-left: 3rem;
  }

  #section-features .feature-left {
    margin-right: 3rem;
  }

  #section-features .feature#f5 {
    margin-right: 3.5rem;
  }

  #section-features .feature#f6 {
    margin-left: 3.5rem;
  }
}

@media screen and (width >= 768px) and (height < 768px) {
  /***** SECTION 3 - WHY US / FEATURES *****/

  #section-features .section-content {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    padding-top: 5rem;
    padding-inline: 3.5rem;
  }

  #section-features .section-title {
    grid-area: 1 / 1 / 2 / -1;
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  #section-features .logo-container {
    display: none;
  }

  #section-features.active .feature {
    flex-direction: row;
    max-width: 50ch;
    margin: 0;
    gap: 0.75rem;
    padding: 1.15rem;
    border: 1px solid currentColor;
    border-radius: 1rem;
  }

  #section-features.active .feature {
    opacity: 1;
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  #section-features.active .feature-left {
    flex-direction: row-reverse;
  }

  #section-features .feature#f5,
  #section-features .feature#f6 {
    margin-inline: 0;
  }

  .feature#f1 {
    grid-area: 2 / 1 / 3 / 2;
    opacity: 0;
    -ms-transform: translate3d(-3rem, 0, 0);
    -o-transform: translate3d(-3rem, 0, 0);
    -moz-transform: translate3d(-3rem, 0, 0);
    -webkit-transform: translate3d(-3rem, 0, 0);
    transform: translate3d(-3rem, 0, 0);
    transition: transform 0.5s ease-in 0.75s,
      opacity 0.5s ease-in 0.75s;
  }
  .feature#f2 {
    grid-area: 2 / 2 / 3 / 3;
    opacity: 0;
    -ms-transform: translate3d(3rem, 0, 0);
    -o-transform: translate3d(3rem, 0, 0);
    -moz-transform: translate3d(3rem, 0, 0);
    -webkit-transform: translate3d(3rem, 0, 0);
    transform: translate3d(3rem, 0, 0);
    transition: transform 0.5s ease-in 1s,
      opacity 0.5s ease-in 1s;
  }
  .feature#f3 {
    grid-area: 3 / 1 / 4 / 2;
    opacity: 0;
    -ms-transform: translate3d(-2.5rem, 0, 0);
    -o-transform: translate3d(-2.5rem, 0, 0);
    -moz-transform: translate3d(-2.5rem, 0, 0);
    -webkit-transform: translate3d(-2.5rem, 0, 0);
    transform: translate3d(-2.5rem, 0, 0);
    transition: transform 0.5s ease-in 1.25s,
      opacity 0.5s ease-in 1.25s;
  }
  .feature#f4 {
    grid-area: 3 / 2 / 4 / 3;
    opacity: 0;
    -ms-transform: translate3d(2.5rem, 0, 0);
    -o-transform: translate3d(2.5rem, 0, 0);
    -moz-transform: translate3d(2.5rem, 0, 0);
    -webkit-transform: translate3d(2.5rem, 0, 0);
    transform: translate3d(2.5rem, 0, 0);
    transition: transform 0.5s ease-in 1.5s,
      opacity 0.5s ease-in 1.5s;
  }
  .feature#f5 {
    grid-area: 4 / 1 / 5 / 2;
    margin-inline: 0;
    opacity: 0;
    -ms-transform: translate3d(-2rem, 0, 0);
    -o-transform: translate3d(-2rem, 0, 0), 
    -moz-transform: translate3d(-2rem, 0, 0);
    -webkit-transform: translate3d(-2rem, 0, 0);
    transform: translate3d(-2rem, 0, 0);
    transition:
      transform 0.5s ease-in 1.75s,
      opacity 0.5s ease-in 1.75s;
  }
  .feature#f6 {
    grid-area: 4 / 2 / 5 / 3;
    margin-inline: 0;
    opacity: 0;
    -ms-transform: translate3d(2rem, 0, 0);
    -o-transform: translate3d(2rem, 0, 0);
    -moz-transform: translate3d(2rem, 0, 0);
    -webkit-transform: translate3d(2rem, 0, 0);
    transform: translate3d(2rem, 0, 0);
    transition:
      transform 0.5s ease-in 2s,
      opacity 0.5s ease-in 2s;
  }

  #section-features.active .feature img {
    margin: 0;
    height: 5em;
    width: 6em;
  }

  #section-contacts #terms {
    margin-top: 1rem;
    font-size: 0.775rem;
  }
}

@media screen and (width >= 1024px) and (height < 768px) {
  /********* SECTION 0 - HOME ***********/

  #greeting {
    padding-top: 2rem;
    font-size: 1.2rem;
  }

  /********* SECTION 2 - ABOUT ***********/

  #about-projects .section-title {
    margin-top: 1.5rem;
    font-size: 1.125rem;
  }

  .fact-card {
    height: unset;
    padding: 0.5rem;
  }

  .fact-card .card-face {
    /* padding-block: 0.5rem 1.5rem; */
    padding-bottom: 2rem;
    font-size: 1.15em;
  }

  .fact-card .card-face .card-subtitle {
    font-size: 1.4rem;
    margin-bottom: 1.2rem;
  }

  .fact-card .card-back {
    padding-left: 0.5rem;
    font-size: 0.75rem;
  }

  /********* SECTION 4 - CONTACT ***********/

  #section-contacts .section-content {
    padding-top: 6.5rem;
  }
}

/******************************************************/
/********************** ANIMATIONS ********************/
/******************************************************/

@keyframes fadeInBg {
  from {
    opacity: 0.2;
    -ms-transform: scale(1.08) rotate(-0.02deg);
    -o-transform: scale(1.08) rotate(-0.02deg);
    -moz-transform: scale(1.08) rotate(-0.02deg);
    -webkit-transform: scale(1.08) rotate(-0.02deg);
    transform: scale(1.08) rotate(-0.02deg);
  }

  to {
    opacity: 0.45;
    -ms-transform: none;
    -o-transform: none;
    -moz-transform: none;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes sildeInFromBottom {
  0% {
    opacity: 0;
    -ms-transform: translate3d(0, 3rem, 0);
    -o-transform: translate3d(0, 3rem, 0);
    -moz-transform: translate3d(0, 3rem, 0);
    -webkit-transform: translate3d(0, 3rem, 0);
    transform: translate3d(0, 3rem, 0);
  }

  75% {
    opacity: 0;
    -ms-transform: translate3d(0, 2.5rem, 0);
    -o-transform: translate3d(0, 2.5rem, 0);
    -moz-transform: translate3d(0, 2.5rem, 0);
    -webkit-transform: translate3d(0, 2.5rem, 0);
    transform: translate3d(0, 2.5rem, 0);
  }

  100% {
    opacity: 1;
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes sildeInFromTop {
  0% {
    opacity: 0;
    -ms-transform: translate3d(0, -3rem, 0);
    -o-transform: translate3d(0, -3rem, 0);
    -moz-transform: translate3d(0, -3rem, 0);
    -webkit-transform: translate3d(0, -3rem, 0);
    transform: translate3d(0, -3rem, 0);
  }

  75% {
    opacity: 0;
    -ms-transform: translate3d(0, -2.75rem, 0);
    -o-transform: translate3d(0, -2.75rem, 0);
    -moz-transform: translate3d(0, -2.75rem, 0);
    -webkit-transform: translate3d(0, -2.75rem, 0);
    transform: translate3d(0, -2.75rem, 0);
  }

  100% {
    opacity: 1;
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes sildeInFromLeft {
  0% {
    opacity: 0;
    -ms-transform: translate3d(-3rem, 0, 0);
    -o-transform: translate3d(-3rem, 0, 0);
    -moz-transform: translate3d(-3rem, 0, 0);
    -webkit-transform: translate3d(-3rem, 0, 0);
    transform: translate3d(-3rem, 0, 0);
  }

  75% {
    opacity: 0;
    -ms-transform: translate3d(-2.75rem, 0, 0);
    -o-transform: translate3d(-2.75rem, 0, 0);
    -moz-transform: translate3d(-2.75rem, 0, 0);
    -webkit-transform: translate3d(-2.75rem, 0, 0);
    transform: translate3d(-2.75rem, 0, 0);
  }

  100% {
    opacity: 1;
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
