:root {
  --font-body-primary: "Roboto Flex", Roboto, Helvetica, Arial, sans-serif;
  --font-body-secondary: Roboto, Helvetica, Arial, sans-serif;
  --font-button-text: var(--font-body-primary);
  --font-logo: "Montserrat Alternates", sans-serif;
  --color-logo-primary: #eee;
  --color-text-primary: #ddd;
  --color-text-dark-primary: #124;
  --color-text-highlight: #f39200;
  --color-bg-primary: #124;
  --color-bg-light: #9eadcb80;
  --color-button-bg: #f39200;
  --color-button-bg-disabled: #9eadcbcc;
  --color-page-nav-button-bg: #ffffff13;
  --color-input-bg: #ffffff1a;
  --color-input-border: #ffffff80;
  --color-alert-error: #8d0707cc;
  --color-alert-success: #1b734abd;
  --color-alert-bg: #5c6d8d;
  --color-modal-bg: #ffffff1a;
  --color-modal-backdrop: #0a1731bf;
  --color-card-default-bg: #ffffff0a;
  --color-card-light-bg: #9eadcb80;
  --color-card-light-hover-bg: #7183a763;
  --color-primary-darktheme: #8b96ad99;
  --color-secondary-darktheme: #9eadcb80;
  --color-bg-darktheme: #ffffff13;
  --color-bg-menu-sm: #3c4a68e6;
  --content-width-xl: 1400px;
  --content-width-lg: 1024px;
  --content-width-md: 768px;
  --lightningcss-light: initial !important;
  --lightningcss-dark:  !important;
  color-scheme: light only !important;
}

*, :before, :after {
  box-sizing: border-box;
  border: none;
  outline: none;
  min-width: 0;
  margin: 0;
  padding: 0;
}

body, html {
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  font-size: 16px;
  line-height: 1.2;
  font-family: var(--font-body-primary);
  font-optical-sizing: auto;
  font-variation-settings: "slnt" 0, "wdth" 100, "GRAD" 0, "XTRA" 468, "YOPQ" 79, "YTAS" 750, "YTDE" -203, "YTFI" 738, "YTLC" 514, "YTUC" 712;
  font-style: normal;
}

body {
  height: 100dvh;
  position: relative;
}

.hidden {
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

a, a:visited, a:focus {
  color: var(--color-text-primary);
  text-decoration: none;
}

.btn {
  font-family: var(--font-button-text);
  letter-spacing: .08ch;
  outline: 1px solid var(--CLR_MENU_BG_HIGHLIGHT);
  background-color: var(--color-button-bg);
  text-shadow: 1px 1px 1px #1e1e1ea6;
  color: #fff;
  text-wrap: nowrap;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  cursor: pointer;
  border-radius: .15rem;
  padding: .75rem 1.5rem;
  font-size: .95rem;
  font-weight: 600;
  position: relative;
  box-shadow: 1px 1px 1px #1e1e1ea6;
}

.btn:active {
  transform: scale(.95)translateY(-1px);
}

.btn:disabled {
  background-color: var(--color-button-bg-disabled);
  pointer-events: none;
  cursor: not-allowed;
}

.btn--spinner {
  pointer-events: none;
  padding-left: 2.75rem;
}

.btn--spinner:before {
  content: "";
  z-index: 50;
  background: url("spinner.430239b0.svg") center / cover no-repeat;
  width: 1.35rem;
  height: 1.35rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(55%, -50%);
}

.btn--dlg-circle {
  text-align: center;
  color: var(--color-text-primary);
  background-color: var(--color-bg-light);
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.btn--dlg-circle:before {
  place-content: center;
  width: 100%;
  height: 100%;
  font-size: 1.16rem;
  font-weight: 300;
  display: grid;
}

.btn--dlg-circle--close:before {
  content: "✕";
}

.btn--dlg-circle--up:before {
  content: "";
}

.md, .lg, .xl {
  display: none;
}

.nowrap {
  white-space: nowrap;
}

header {
  z-index: 50;
  justify-content: space-between;
  width: 100%;
  padding: .25em .7em 0 .5em;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.logo {
  font-size: .6rem;
  font-weight: 400;
  font-family: var(--font-logo);
  white-space: nowrap;
  -o-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  color: var(--color-logo-primary);
  text-shadow: 1px 2px 2px #000000a6;
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light only;
  padding-left: 4.75em;
  position: relative;
}

.logo:before {
  content: "";
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light only;
  background-image: url("logo-white.376b7612.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 80%;
  width: 5em;
  height: 5em;
  position: absolute;
  top: 0;
  left: 0;
}

.logo__brand {
  white-space: nowrap;
  font-size: 2.85em;
  line-height: 1.15;
}

.logo__sub {
  letter-spacing: .068em;
  white-space: nowrap;
  font-size: 1.05em;
  line-height: .85;
}

#menu-hamburger, #menu-close {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  cursor: pointer;
  background-color: #0000;
  border: none;
  outline: none;
  width: 36px;
  height: 36px;
}

#menu-hamburger svg, #menu-close svg {
  fill: #fff;
  width: 100%;
  height: 100%;
}

header #top-nav {
  z-index: 15;
  background-color: var(--color-bg-menu-sm);
  border: 1px solid var(--color-input-border);
  border-start-start-radius: .5rem;
  border-end-start-radius: .5rem;
  width: 24ch;
  transition: transform .3s ease-out;
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden;
  transform: translate3d(100%, 0, 0);
}

header #top-nav.active {
  transform: translate3d(0, 0, 0);
}

#menu-hamburger {
  align-self: end;
}

#top-nav.active #menu-close {
  z-index: 20;
  position: absolute;
  top: .8rem;
  right: .7rem;
}

#top-nav.active + #menu-hamburger {
  display: none;
}

#top-nav.active + #menu-close {
  display: block;
}

header #top-menu {
  flex-direction: column;
  list-style: none;
  display: flex;
}

header #top-menu .menu-item {
  font-size: 1.1rem;
  font-family: var(--font-body-secondary);
  letter-spacing: .1ch;
  text-shadow: 1px 1px 1px #1e1e1ea6;
  text-wrap: nowrap;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  margin-inline: 1px;
  padding: 1.25rem .75rem;
  font-weight: 500;
}

.menu-item:not(:last-child) {
  border-bottom: 1px solid #eee;
}

header #top-menu .menu-item.active {
  background-color: var(--color-alert-bg);
  color: #fff;
  text-shadow: 1px 1px 1px #1e1e1ecc;
  font-weight: 700;
}

.nav-btn {
  color: #0000;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

main {
  position: absolute;
  inset: 0;
  overflow: clip;
}

#footer-nav {
  display: none;
}

.modal {
  pointer-events: all;
  z-index: 99;
  opacity: 1;
  place-content: center;
  transition: opacity 1s;
  display: grid;
  position: absolute;
  inset: 0;
}

.modal.hidden {
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s;
}

.modal__title {
  text-align: center;
  font-size: 1.05rem;
  font-weight: 400;
  font-family: var(--font-body-secondary);
  color: var(--color-text-highlight);
  text-transform: uppercase;
  margin-bottom: .75rem;
}

.modal__content {
  text-shadow: 1px 2px 2px #000000a6;
  pointer-events: all;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.modal__btn {
  color: var(--color-text-primary);
  border: 2px solid var(--color-text-primary);
  margin-inline: auto;
  background-color: #0000;
  border-radius: 100rem;
  margin-top: 1rem;
  padding: .5rem 2rem;
  display: block;
}

.modal__btn-close:before {
  content: "OK";
  font-size: 1.05em;
  font-weight: 600;
}

.modal__button:active, .modal__button:focus {
  background-color: #0000;
}

dialog {
  color: var(--color-text-primary);
  background-color: #0000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

dialog::backdrop {
  filter: blur(8px);
  pointer-events: none;
  background-color: #0a1731bf;
}

.accordion-section {
  border-top: 1px solid var(--color-input-border);
}

.accordion-section:last-of-type {
  border-bottom: 1px solid var(--color-input-border);
}

.accordion-section h4 {
  background-color: var(--color-bg-menu-sm);
  justify-content: space-between;
  align-items: center;
  padding: .5rem .1rem .5rem .6rem;
  display: flex;
}

.accordion-section h4:after {
  content: url("expand_more_FILL0_wght400_GRAD0_opsz28.6ad46790.svg");
  color: var(--color-text-primary);
  filter: invert();
  width: 28px;
  height: 28px;
}

.accordion-section.expanded h4:after {
  transform: rotate(180deg);
}

.accordion-section:not(.expanded) > :not(h4) {
  visibility: hidden;
  opacity: 0;
  height: 0;
}

.accordion-section.expanded p {
  padding: .5rem .5rem .75rem;
  font-size: 1.05rem;
  line-height: 1.25em;
}

.content-scroller {
  flex-direction: column;
  width: 100%;
  height: 500dvh;
  transition: top 1s;
  display: flex;
  position: absolute;
  left: 0;
  overflow-y: hidden;
}

.content-scroller[data-section="0"] {
  top: 0;
}

.content-scroller[data-section="1"] {
  top: -100dvh;
}

.content-scroller[data-section="2"] {
  top: -200dvh;
}

.content-scroller[data-section="3"] {
  top: -300dvh;
}

.content-scroller[data-section="4"] {
  top: -400dvh;
}

.section {
  background-color: #0000;
  width: 100vw;
  height: 100dvh;
  position: relative;
  overflow: hidden;
}

section > .section-bg {
  opacity: .15;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  inset: 0;
}

.section-title {
  font-size: 1.075rem;
  font-weight: 400;
  font-family: var(--font-body-primary);
  text-align: center;
  text-transform: uppercase;
  color: var(--color-text-highlight);
  text-shadow: 1px 2px 2px #000000a6;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  line-height: 1;
}

.section-subtitle, .section .subtitle {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.6;
  font-family: var(--font-body-primary);
  text-shadow: 1px 2px 2px #000000a6;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.section-content {
  width: 100%;
  height: 100%;
  padding: .6rem;
  position: relative;
  overflow: hidden;
}

.cardset {
  flex-wrap: nowrap;
  justify-content: center;
  list-style: none;
  display: flex;
}

.card {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  border: 1px solid #ffffff40;
  border-radius: .35rem;
  place-content: center;
  display: grid;
}

.card .card-wrapper {
  overflow: hidden;
}

#section-home > .section-bg {
  opacity: .2;
  background-image: url("12.0b0e20ba.webp");
}

#section-home .section-content {
  place-content: center start;
  height: 100%;
  display: grid;
}

#greeting {
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  padding-block: 3rem .5rem;
  padding-inline: min(2ch, 10vw);
  font-size: .8rem;
}

#greeting-message {
  text-align: justify;
  font-size: 1.375em;
  font-weight: 400;
  font-family: var(--font-body-primary);
  color: var(--color-text-highlight);
  text-shadow: 1px 1px 1px #0009;
  opacity: 0;
  margin-bottom: .75rem;
}

#greeting-motto {
  text-shadow: 2px 2px 2px #000000b3;
  opacity: 0;
  width: 10ch;
  margin-bottom: 1.25rem;
  font-size: 3.75em;
  font-weight: 700;
  line-height: 1.1;
}

#greeting-subtitle {
  text-shadow: 1px 2px 2px #000000a6;
  opacity: 0;
  margin-bottom: 1.8rem;
  font-size: 1.45em;
  line-height: 1.4;
}

#greeting .btn {
  opacity: 0;
}

#greeting .btn:active {
  transform: scale(.95);
}

section.active #greeting > #greeting-message {
  opacity: 1;
  animation: 1.25s forwards sildeInFromTop;
}

section.active #greeting > #greeting-motto {
  opacity: 1;
  animation: 2.25s forwards sildeInFromLeft;
  transform: translate3d(0, 0, 0);
}

section.active #greeting-subtitle {
  opacity: 1;
  animation: 1.75s forwards sildeInFromBottom;
}

section.active #greeting .btn {
  opacity: 1;
  animation: 2.5s linear .4s both FadeIn;
}

#section-services > .section-bg {
  opacity: .15;
  background-image: url("03.dc8268e1.webp");
}

#section-services .section-title {
  text-align: center;
  opacity: 0;
  margin-block: .75rem .6rem;
  transition: all .35s ease-out .7s;
  display: block;
}

#section-services.active .section-title {
  opacity: 1;
}

#section-services .section-title.hidden {
  display: none;
}

#section-services .section-content {
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  z-index: 2;
  height: 100%;
  padding-top: 6rem;
}

#section-services #services-list {
  text-transform: uppercase;
  color: #fff;
  opacity: 0;
  border-radius: .25em;
  width: 39ch;
  margin-inline: auto;
  font-size: .85em;
  font-weight: 500;
  list-style: none;
  transition: opacity 1.5s ease-out .5s;
  display: block;
}

#section-services #services-list.hidden {
  display: none;
}

#section-services.active #services-list {
  opacity: 1;
}

#section-services.active #services-list .service {
  align-items: center;
  display: flex;
}

.service-icon-container {
  border: 1px solid #fff;
  border-radius: 50%;
  place-content: center;
  width: 32px;
  height: 32px;
  margin-inline: 0 .45rem;
  display: grid;
}

#services-list > .service.active .service-icon-container {
  border-width: 2px;
  border-color: #444;
}

#services-list > .service.active svg {
  fill: #333;
}

#section-services .service {
  text-shadow: 1px 2px 2px #000000a6;
  cursor: pointer;
  background-color: #ffffff0d;
  border: 1px solid #ffffff40;
  padding: 1em .75em;
}

#section-services .service:not(:last-child) {
  border-bottom: 0;
}

#section-services .service:last-child {
  border-bottom-right-radius: .5em;
  border-bottom-left-radius: .5em;
}

#section-services .service:first-child {
  border-top-left-radius: .35em;
  border-top-right-radius: .35em;
}

#section-services .service.active {
  color: #333;
  text-shadow: 1px 2px 2px #ffffff73;
  cursor: unset;
  background-color: #ffffffbf;
  font-weight: 700;
}

#section-services .service-name {
  text-transform: uppercase;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  align-items: center;
  padding-inline: 1.75rem 45px;
  font-size: .95rem;
  font-weight: 700;
  display: flex;
  position: relative;
}

.service-details.active .service-name:after {
  content: url("expand_less_FILL1_wght400_GRAD0_opsz32.30f6d20a.svg");
  text-align: center;
  color: var(--color-text-primary);
  background-color: var(--color-bg-light);
  text-shadow: none;
  border: none;
  border-radius: 50%;
  place-content: center;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 2px 0 0;
  display: grid;
  position: absolute;
  top: 8px;
  right: 8px;
}

#section-services .service-name svg {
  display: none;
}

#section-services .service-details {
  visibility: hidden;
  opacity: 0;
  transition: all .15s ease-out;
  display: none;
}

#section-services .service-details.active {
  background-color: var(--color-modal-bg);
  text-shadow: 1px 2px 2px #000000a6;
  visibility: visible;
  opacity: 1;
  border: 1px solid #ffffff59;
  border-radius: .35rem;
  margin-inline: auto;
  transition: opacity 2s ease-out .25s;
  display: inline-block;
}

#section-services.active:has(.service-details.active) .section-title {
  display: none;
}

#section-services .service-details.active .service-name > span:before {
  content: "OUR SERVICES:";
  padding-top: 8px;
  line-height: 1.5;
  display: block;
}

#section-services .service-description {
  padding: .75rem .75rem .5rem 1.75rem;
  list-style-type: circle;
}

#section-services .service-description > li {
  margin-bottom: .6rem;
  font-size: .95em;
  line-height: 1.3;
}

#section-services .service-description > li:has( + li.md) {
  margin-bottom: .25rem;
}

.service-name svg {
  width: 24px;
  height: 24px;
  margin-inline: .5em .35em;
}

#section-about > .section-bg {
  opacity: .15;
  background-image: url("01.6b851781.webp");
}

#section-about > .section-content {
  place-content: center;
  height: 100dvh;
  padding-block: 3.5rem .5rem;
  font-size: .95rem;
  display: grid;
}

#about-facts {
  gap: .4rem;
  margin-inline: .25rem;
}

.fact-card {
  background-color: var(--color-card-light-bg);
  padding: .5rem;
  place-content: start center;
  width: 100%;
  max-width: 230px;
  padding-bottom: 1rem;
}

.fact-card .card-face, .fact-card .card-back {
  text-align: center;
  font-family: var(--font-body-secondary);
  text-shadow: 1px 2px 2px #000000a6;
}

.fact-card .card-face {
  font-size: .925rem;
}

.fact-card .card-back {
  display: none;
}

.fact-card .card-face .card-title {
  font-size: 3.5rem;
  line-height: 3.5rem;
  font-family: var(--font-body-secondary);
  text-shadow: 2px 2px 2px #2f2f2fa6;
  margin-bottom: .5rem;
  font-weight: 500;
}

.fact-card .card-face .card-subtitle {
  margin-bottom: .75rem;
  font-size: 1.15rem;
  font-weight: 400;
  line-height: 1.15;
}

.fact-card .card-back {
  font-size: .85rem;
  line-height: 1.35;
}

.fact-card .card-back p:nth-of-type(2) {
  margin-top: .5rem;
}

#section-about .section-title {
  opacity: 0;
  margin-block: 1.5rem 1rem;
  transition: all .75s ease-out .5s;
  transform: scale(.85);
}

#section-about.active .section-title {
  opacity: 1;
  transform: scale(1);
}

#projects-list {
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  max-width: 800px;
  margin: auto;
  padding: 0;
  list-style: none;
  display: flex;
}

.card.project-card {
  aspect-ratio: 25 / 15;
  background-color: #d6d6d6;
  border: 1px solid #d6d6d626;
  width: 46%;
  max-width: 230px;
  overflow: hidden;
  box-shadow: 1px 1px 5px #00000040;
}

.card.project-card img {
  object-fit: cover;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

#section-features > .section-bg {
  opacity: .1;
  background-image: url("02.ea97fc56.webp");
}

#section-features .section-content {
  z-index: 2;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  place-content: center;
  padding-block: 3.5rem .5rem;
  padding-inline: .75rem;
  display: grid;
}

#section-features .section-title {
  text-align: center;
  letter-spacing: .02em;
  opacity: 0;
  margin-inline: auto;
  max-width: 32ch;
  margin-bottom: 1rem;
  font-size: 1.05em;
  line-height: 1.2;
  transition: all .75s ease-out .5s;
  transform: scale(.85);
}

#section-features.active .section-title {
  opacity: 1;
  transform: scale(1);
}

#section-features .feature {
  opacity: 0;
  gap: .5em;
  margin-bottom: .65em;
  font-size: .9rem;
  line-height: 1.1;
  transition: all .85s ease-out .5s;
  display: flex;
}

#section-features .feature img {
  aspect-ratio: 1.2;
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light only;
  height: 4.5em;
  display: block;
}

#section-features .feature .feature-text {
  border-bottom: 1px solid #d6d6d666;
  width: 100%;
  padding-bottom: .5em;
  display: block;
}

#section-features .feature-title {
  margin-bottom: .25em;
  font-size: 1.1em;
  font-weight: 600;
  line-height: 1.2;
}

#section-features .feature-left {
  transform: translate3d(-30%, 0, 0);
}

#section-features .feature-right {
  flex-direction: row-reverse;
  transform: translate3d(30%, 0, 0);
}

#section-features .feature-right .feature-text {
  text-align: end;
}

#section-features.active .feature {
  opacity: 1;
  transform: scale(1)translate(0);
  text-shadow: 1px 2px 2px #000000a6 !important;
}

#section-features .logo-container {
  display: none;
}

#section-contacts > .section-bg {
  opacity: .2;
  background-image: url("11.f2aebc29.webp");
}

#section-contacts.active {
  z-index: 9;
  position: fixed;
  bottom: 0;
}

#section-contacts .section-content {
  opacity: 0;
  visibility: hidden;
  place-content: center stretch;
  padding-block: 3.25rem 1.75rem;
  transition: opacity 1.2s .5s;
  display: grid;
}

#section-contacts.active .section-content {
  opacity: 1;
  visibility: visible;
}

#section-contacts.active .section-content.hidden {
  opacity: 0;
  pointer-events: none;
  transition: opacity .5s;
}

#section-contacts .content-wrapper {
  padding-inline: .15rem;
}

#section-contacts .section-title {
  text-align: start;
  font-size: 1.05rem;
}

#contact-page-header {
  padding: .35rem;
  grid-area: 2 / 1 / 3 / 2;
  padding-bottom: .5rem;
}

#contact-form-wrapper {
  -webkit-user-drag: none;
  grid-area: 3 / 1 / 4 / 2;
}

.input-container {
  border: 1px solid var(--color-input-border);
  background-color: var(--color-input-bg);
  border-radius: .2rem;
  outline: 2px solid #0000;
  width: 100%;
  margin-bottom: .7rem;
  position: relative;
}

.input-container:focus-within {
  outline-color: #ffffffbf;
}

#contact-form input, #contact-form textarea {
  font-size: 1rem;
  line-height: 2.5rem;
  font-family: var(--font-body-primary);
  color: currentColor;
  background-color: #0000;
  width: calc(100% - 2.25rem);
  margin-left: 2rem;
}

#contact-form textarea {
  height: 4.45rem;
  padding-block: .55rem .15rem;
  line-height: 1.25;
}

#contact-form .input-container:has(input.invalid) {
  border-color: var(--color-input-border-error);
}

.input-icon {
  position: absolute;
  top: .7rem;
  left: .35rem;
}

.input-icon:has( ~ textarea) {
  top: .5rem;
}

#contact-form .contact-input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-text-primary);
  -webkit-box-shadow: inset 0 0 0 30px #26324a !important;
}

#contact-form .contact-input:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--color-text-primary);
  -webkit-box-shadow: inset 0 0 0 30px #26324a !important;
}

#contact-form .contact-input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-text-primary);
  -webkit-box-shadow: inset 0 0 0 30px #26324a !important;
}

#contact-form .contact-input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--color-text-primary);
  -webkit-box-shadow: inset 0 0 0 30px #26324a !important;
}

#contact-form .input-container:has(.contact-input:autofill) {
  -webkit-text-fill-color: var(--color-text-primary);
  -webkit-box-shadow: inset 0 0 0 30px #26324a !important;
}

#contact-form .input-container:has(.contact-input:autofill:hover) {
  -webkit-text-fill-color: var(--color-text-primary);
  -webkit-box-shadow: inset 0 0 0 30px #26324a !important;
}

#contact-form .input-container:has(.contact-input:autofill:focus) {
  -webkit-text-fill-color: var(--color-text-primary);
  -webkit-box-shadow: inset 0 0 0 30px #26324a !important;
}

#contact-form .input-container:has(.contact-input:autofill:active) {
  -webkit-text-fill-color: var(--color-text-primary);
  -webkit-box-shadow: inset 0 0 0 30px #26324a !important;
}

#contact-form .form-group {
  -webkit-user-drag: none;
  position: relative;
}

#contact-form .btn {
  margin-inline: auto 0;
  display: block;
}

#contact-form .btn:focus-visible {
  outline: solid 2px var(--color-text-primary);
}

#contact-form-dialog {
  width: 100%;
}

#contact-form-dialog .content-wrapper {
  background-color: var(--color-alert-bg);
  color: var(--color-text-primary);
  text-shadow: 1px 1px 1px #000000a6;
  border: 1px solid #fff;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 7rem;
  padding: .6rem .7rem;
  display: flex;
  box-shadow: 1px 1px 3px #000000a6;
}

#contact-form-dialog .dialog-title {
  margin-bottom: 1.25rem;
  font-size: 1.15rem;
  font-weight: 500;
}

#contact-form-dialog.error .btn--dlg-circle {
  background-color: #a42d20ab;
}

#contact-form-dialog.error .btn--dlg-circle:before {
  content: "✕";
}

#contact-form-dialog.success .btn--dlg-circle {
  background-color: var(--color-alert-success);
  border: 1px solid var(--color-input-border);
}

#contact-form-dialog.success .btn--dlg-circle:before {
  content: "✓";
  font-size: 1.35rem;
  font-weight: 500;
}

#contact-form-dialog .dialog-message {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.45;
}

#contact-form-dialog .dialog-message b {
  display: none;
}

#contact-form-dialog ul {
  list-style-type: disclosure-closed;
  list-style-position: inside;
}

#contact-locations {
  position: relative;
}

#contact-locations-header {
  font-size: 1.05rem;
  font-weight: 400;
  font-family: var(--font-body-primary);
  text-transform: uppercase;
  color: var(--color-text-highlight);
  text-shadow: 1px 2px 2px #000000a6;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  margin-bottom: .25rem;
}

#contact-warning, .contact-location {
  text-shadow: 1px 1px 1px #000000a6;
  grid-template-columns: auto 1fr;
  column-gap: .5rem;
  margin-bottom: .7rem;
  padding: .5rem .75rem;
  font-size: .95em;
  line-height: 1.35;
  display: grid;
}

#contact-warning {
  cursor: pointer;
  align-items: center;
  margin-bottom: 0;
  line-height: 1.35;
}

#contact-warning.expanded {
  background-color: #394c71;
  place-content: center stretch;
  padding-inline: 1rem 2.75rem;
  font-size: 1rem;
  display: grid;
  position: absolute;
  inset: 0 0 -2rem;
}

#contact-warning.expanded p:not(:last-child) {
  margin-bottom: .5rem;
}

#contact-warning:not(.expanded) .exp-hidden, #contact-warning.expanded .exp-visible {
  display: block;
}

#contact-warning:not(.expanded) .exp-visible, #contact-warning.expanded .exp-hidden {
  display: none;
}

#contact-warning-icon {
  width: 21px;
  height: 21px;
}

#section-contacts #terms {
  place-content: start center;
  padding: 5.5rem 1rem 1rem;
}

#section-contacts #terms ul {
  margin-left: .5rem;
  line-height: 1.35;
  list-style-position: inside;
}

#section-contacts.expanded #terms ul {
  margin-bottom: 1.5rem;
}

.footer {
  text-align: center;
  z-index: 50;
  line-height: 1.75;
  font-family: var(--font-body-secondary);
  letter-spacing: .05ch;
  padding-bottom: .5rem;
  font-size: .8rem;
  font-weight: 500;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer #footer__corp {
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.footer #footer__copyright {
  font-size: .85rem;
  font-family: var(--font-body-secondary);
}

.footer #footer__terms {
  cursor: pointer;
}

@media screen and (width <= 399.999px) and (height <= 675px) {
  #greeting {
    font-size: .7rem;
  }

  #section-services .section-content {
    padding-top: 3.75rem;
  }

  #section-services .section-title, #section-services .service-name, #section-services .service-description > li {
    font-size: .95rem;
  }

  .fact-card {
    padding: .35rem .35rem .5rem;
  }

  .fact-card .card-face {
    font-size: .875rem;
  }

  .fact-card .card-face .card-title {
    margin-bottom: 0;
    font-size: 3.25rem;
  }

  .fact-card .card-face .card-subtitle {
    font-size: 1.025rem;
  }

  #section-about.active .section-title {
    margin-block: 1.25rem .5rem;
    font-size: .975rem;
  }

  #projects-list {
    gap: .6rem;
  }

  .card.project-card {
    width: 47%;
  }

  #section-features .section-title {
    font-size: .975rem;
  }

  #section-features .feature {
    font-size: .85rem;
  }

  #section-contacts .section-content, #contact-form input, #contact-form textarea {
    font-size: .95rem;
  }

  #contact-page-header {
    display: none;
  }

  #contact-locations-header {
    font-size: .975rem;
  }

  #contact-warning.expanded {
    bottom: unset;
  }

  .modal__title, .accordion-section.expanded p {
    font-size: .975rem;
  }

  #section-contacts #terms {
    padding-top: 4rem;
  }
}

@media screen and (width >= 600px) {
  .sm {
    display: none;
  }

  .md {
    display: unset;
  }

  li.md {
    display: list-item;
  }

  header #top-nav {
    width: 26ch;
  }

  .section .section-title {
    font-size: 1.075rem;
    font-weight: 500;
    font-family: var(--font-body-primary);
    color: var(--color-text-highlight);
    text-shadow: 1px 1px 1px #0009;
    letter-spacing: unset;
  }

  #greeting {
    width: 60ch;
    max-inline-size: 80%;
    margin-left: min(2rem, 10vw);
  }

  #section-services .section-content {
    padding-inline: .75rem;
  }

  #section-services .section-title {
    text-align: center;
    margin-inline: 0;
  }

  #section-services #services-list {
    width: 380px;
  }

  #section-features .section-content {
    place-content: start center;
    padding-top: 6rem;
  }

  #section-features .section-title, #section-features .feature {
    letter-spacing: unset;
    margin-inline: auto;
    max-width: 480px;
    margin-bottom: 1.5rem;
  }

  #section-contacts .section-content {
    place-content: start;
    padding: 6rem 1rem 2rem 2rem;
  }

  #section-contacts .section-title {
    margin-bottom: .25rem;
  }

  #contact-form-dialog {
    width: 420px;
  }

  #contact-locations {
    grid-template-columns: 1fr 1fr;
    column-gap: .75rem;
    display: grid;
  }

  .contact-location {
    font-size: 1em;
  }

  #contact-warning {
    grid-area: 2 / 1 / 3 / 3;
    font-size: 1em;
    line-height: 1.35;
  }

  #contact-warning.expanded {
    inset: unset;
    background-color: #3d527cbf;
    display: block;
  }
}

@media screen and (width >= 768px) {
  header {
    padding: .5rem .75rem 0;
  }

  header #main-logo {
    font-size: .65rem;
  }

  #footer-nav {
    text-align: center;
    z-index: 50;
    width: 100%;
    display: block;
    position: fixed;
    bottom: 0;
  }

  #footer-nav > .nav-btn {
    opacity: .9;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='40' width='40' viewBox='0 -960 960 960' %3E%3Cpath d='m480-359.514 162.384-162.383-35.999-34.999L480-430.512 353.615-556.896l-35.999 34.999L480-359.514Zm.067 259.513q-78.426 0-147.666-29.92t-120.887-81.544q-51.647-51.624-81.58-120.833-29.933-69.21-29.933-147.635 0-78.836 29.92-148.204 29.92-69.369 81.544-120.682 51.624-51.314 120.833-81.247 69.21-29.933 147.635-29.933 78.836 0 148.204 29.92 69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 148.173 0 78.426-29.92 147.666t-81.21 120.887q-51.291 51.647-120.629 81.58-69.337 29.933-148.173 29.933ZM480-150.256q137.795 0 233.769-96.18Q809.744-342.615 809.744-480q0-137.795-95.975-233.769Q617.795-809.744 480-809.744q-137.385 0-233.564 95.975-96.18 95.974-96.18 233.769 0 137.385 96.18 233.564 96.179 96.18 233.564 96.18ZM480-480Z' fill='%23eeeeee'/%3E%3C/svg%3E");
    width: 48px;
    height: 48px;
    margin-bottom: .25rem;
  }

  #footer-nav:hover > .nav-btn {
    opacity: .9;
  }

  section.active > .section-bg {
    animation: 2.75s cubic-bezier(.3, 0, .7, 1) both fadeInBg;
  }

  section .section-title {
    letter-spacing: unset;
    font-size: 1.075rem;
  }

  #greeting #greeting-message {
    margin-block: 1.45rem;
    font-size: 1.15rem;
    font-weight: 500;
  }

  #greeting-motto {
    margin-bottom: 3rem;
  }

  #greeting-subtitle {
    margin-bottom: 1.75rem;
    font-size: 1.4em;
    line-height: 1.45;
  }

  #greeting .btn {
    font-size: 1.2em;
  }

  header #top-menu .menu-item {
    padding-block: 1rem;
    font-size: 1.05rem;
  }

  #section-services .section-content {
    grid-template-rows: max-content;
    grid-template-columns: auto 1fr;
    grid-auto-flow: row;
    gap: .25em;
    padding: 5rem .5rem 0 1.5rem;
    display: grid;
  }

  #section-services .section-title {
    text-align: left;
    grid-area: 1 / 1 / 2 / 2;
    margin-block: 0 .5rem;
    padding-left: .75rem;
    font-size: 1.09rem;
  }

  #section-services #services-list {
    grid-area: 2 / 1 / 3 / 2;
    width: 39ch;
  }

  #services-list > .service:hover .service-icon-container {
    border: 1px solid #f39200;
  }

  #services-list > .service:hover svg {
    fill: #f39200;
  }

  #services-list > .service.active .service-icon-container {
    border-width: 2px;
    border-color: #444;
  }

  #services-list > .service.active svg {
    fill: #333;
  }

  #section-services .service:not(.active):hover {
    color: var(--color-text-highlight);
  }

  #section-services .service-name {
    background: none;
    outline: none;
    height: auto;
    padding-inline: .3rem 0;
    font-size: 1.25em;
    font-weight: 600;
  }

  .service-details.active .service-name:after {
    display: none;
  }

  #section-services .service-name svg {
    vertical-align: middle;
    fill: #ccc;
    width: 28px;
    height: 28px;
    margin-inline: 0 .35rem;
    display: inline-block;
  }

  #section-services .service-details {
    left: unset;
    right: unset;
    border-radius: .5rem;
    grid-area: 2 / 2 / 3 / 3;
    height: fit-content;
    padding-inline: 2rem 1rem;
    display: grid;
    position: relative;
    transform: scale(.85)translate3d(0, -32px, 0);
  }

  #section-services .service-details.active {
    margin: unset;
    background-color: unset;
    border: none;
    padding-top: .35rem;
    transition: transform .7s ease-out .25s, opacity 2s ease-out .25s;
    transform: scale(1)translate3d(0, 0, 0);
  }

  #section-services .service-details.active .service-name > span:before {
    display: none;
  }

  #section-services .service-description {
    padding: .75rem .5rem .25rem;
    list-style-type: disc;
  }

  #section-services .service-description > li {
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.4;
  }

  #btn-service-description-prev, #btn-service-description-next {
    display: none;
  }

  #section-about > .section-content {
    padding-block: 4.25rem 3.25rem;
  }

  #about-facts {
    gap: 1rem;
    width: 100%;
    max-width: 800px;
    margin: auto;
  }

  .fact-card .card-face {
    padding-block: .5rem 1.5rem;
    font-size: 1.15em;
  }

  .fact-card .card-face .card-title {
    text-shadow: 1px 2px 2px #2f2f2fa6;
    font-size: 4.75rem;
    line-height: 1;
  }

  .fact-card .card-face .card-subtitle {
    margin-bottom: 1.2rem;
    font-size: 1.4rem;
  }

  #about-projects .section-title {
    margin-block: 2.5rem 1rem;
    font-size: 1.095rem;
  }

  .card.project-card img {
    transition: all .25s;
  }

  .card.project-card:hover img {
    transform: scale(1.05);
  }

  #section-features .section-content {
    grid-template-columns: 1fr auto auto 1fr;
    gap: .5em;
    margin-inline: auto;
    padding-block: 6rem 3.15rem;
    display: grid;
  }

  #section-features .feature {
    flex-direction: column;
    max-width: 33ch;
  }

  #section-features .feature-left {
    justify-self: end;
  }

  #section-features .feature-right {
    justify-self: start;
  }

  #section-features .feature-right .feature-text {
    text-align: start;
  }

  #section-features .feature img {
    aspect-ratio: 1;
    height: 5em;
  }

  #section-features .feature-left img {
    margin-left: auto;
  }

  #section-features .feature-right img {
    margin-right: auto;
  }

  #section-features .feature-title {
    margin-bottom: .5em;
    font-size: 1.05rem;
    font-weight: 600;
    line-height: 1.2;
  }

  #section-features .section-title {
    text-align: center;
    opacity: .3;
    margin-inline: auto;
    grid-area: 1 / 1 / 2 / 5;
    max-width: 46ch;
    margin-bottom: 1.1rem;
    font-size: 1.1rem;
    transition: all 1.5s ease-in;
    transform: scale(.75)translate3d(0, -.5em, 0);
  }

  .feature#f1 {
    text-align: right;
    opacity: 0;
    grid-area: 2 / 1 / 3 / 2;
    transition: all .75s ease-out .5s;
    transform: scale(.9)translate3d(-10px, -10px, 0);
  }

  .feature#f2 {
    opacity: 0;
    grid-area: 2 / 4 / 3 / 5;
    transition: all .75s ease-out 1.3s;
    transform: scale(.9)translate3d(10px, -10px, 0);
  }

  .feature#f3 {
    text-align: right;
    opacity: 0;
    grid-area: 3 / 1 / 4 / 2;
    transition: all .75s ease-out 1.7s;
    transform: scale(.9)translate3d(-10px, 10px, 0);
  }

  .feature#f4 {
    opacity: 0;
    grid-area: 3 / 4 / 4 / 5;
    transition: all .75s ease-out .9s;
    transform: scale(.9)translate3d(10px, 10px, 0);
  }

  .feature#f5 {
    text-align: right;
    opacity: 0;
    grid-area: 4 / 1 / 5 / 3;
    margin-right: 2rem;
    transition: all .75s ease-out 2.5s;
    transform: scale(.9)translate3d(-10px, 10px, 0);
  }

  .feature#f6 {
    opacity: 0;
    grid-area: 4 / 3 / 5 / 5;
    margin-left: 2rem;
    transition: all .75s ease-out 2.1s;
    transform: scale(.9)translate3d(10px, 10px, 0);
  }

  #section-features.active .section-title {
    opacity: 1;
    transform: scale(1)translateY(0);
  }

  #section-features.active .feature {
    transform: scale(1)translate3d(0, 0, 0);
    text-shadow: 1px 2px 2px #000000a6 !important;
  }

  #section-features .logo-container {
    grid-area: 2 / 2 / 4 / 4;
    justify-self: center;
    width: 33vw;
    max-width: min(45vw, 45vh);
    padding: .75em;
    display: grid;
  }

  #section-features .logo__circle {
    aspect-ratio: 1;
    color: var(--color-button-bg);
    opacity: 0;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 2s ease-in .5s;
    display: flex;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: inset 0 0 20px 10px #ffffff80;
  }

  #section-features.active .logo__circle {
    opacity: 1;
  }

  #section-features .logo__img {
    aspect-ratio: 1;
    pointer-events: none;
    width: 45%;
  }

  #section-features .logo__brand {
    font-size: 1.75em;
    font-weight: 400;
    line-height: 1;
    font-family: var(--font-logo);
    white-space: nowrap;
    text-shadow: 1px 1px 1px #0009;
  }

  #section-contacts .section-content {
    padding-left: 3.5rem;
  }

  #section-contacts .section-title {
    letter-spacing: .05ch;
    margin-bottom: .15rem;
    font-size: 1.085rem;
  }

  #section-contacts .content-wrapper {
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 380px minmax(300px, 1fr);
    column-gap: 1.5rem;
    width: 100%;
    max-width: 700px;
    padding: 0;
    display: grid;
  }

  #contact-page-header {
    grid-area: 1 / 1 / 2 / -1;
    padding-bottom: 1.5rem;
  }

  #contact-form-wrapper {
    grid-area: 3 / 1 / 4 / 2;
  }

  #contact-form {
    margin-bottom: 1rem;
  }

  .input-container {
    border: 1px solid var(--color-input-border);
    background-color: var(--color-input-bg);
    border-radius: .2rem;
    outline: 2px solid #0000;
    width: 100%;
    margin-bottom: .85rem;
    position: relative;
  }

  .input-container:focus-within {
    outline-color: #ffffffbf;
  }

  #contact-form input, #contact-form textarea {
    font-size: 1rem;
    line-height: 2.5rem;
    font-family: var(--font-body-secondary);
    color: currentColor;
    background-color: #0000;
    width: calc(100% - 2.25rem);
    margin-left: 2rem;
  }

  #contact-form textarea {
    height: unset;
    padding-block: .55rem;
    line-height: 1.35;
  }

  #contact-form .input-container:has(input.invalid) {
    border-color: var(--color-input-border-error);
  }

  .input-icon {
    position: absolute;
    top: .7rem;
    left: .35rem;
  }

  .input-icon:has( ~ textarea) {
    top: .5rem;
  }

  #contact-form .form-group {
    position: relative;
  }

  #contact-form .btn {
    margin: unset;
    display: inline;
  }

  #contact-form .btn:focus-visible {
    outline: solid 2px var(--color-text-primary);
  }

  #contact-form-dialog .dialog-message {
    font-size: 1.05rem;
  }

  #contact-locations-header {
    font-size: 1.05rem;
    line-height: 1;
    font-family: var(--font-body-secondary);
    letter-spacing: unset;
    text-transform: none;
    color: currentColor;
    grid-area: 2 / 2 / 3 / 3;
    align-self: end;
    margin: auto auto .5rem .7rem;
  }

  #contact-locations {
    grid-area: 3 / 2 / 4 / 3;
    display: block;
  }

  .footer {
    text-align: center;
    z-index: 10;
    line-height: 1.75;
    font-family: var(--font-body-secondary);
    letter-spacing: .05ch;
    padding-bottom: .5rem;
    font-size: .8rem;
    font-weight: 500;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .footer #footer__terms {
    cursor: pointer;
  }

  .card__warning, #contact-warning {
    max-width: unset;
  }

  #section-contacts #terms {
    padding-block: unset;
    place-content: center;
    font-size: .975rem;
  }

  #section-contacts #terms .modal__content {
    border: 2px solid var(--color-input-border);
    background-color: var(--color-modal-bg);
    border-radius: .5rem;
    max-width: 800px;
    padding: 1rem;
    position: relative;
  }

  .accordion-section:first-of-type {
    border-top: none;
  }

  .accordion-section:last-of-type {
    border-bottom: none;
  }

  #section-contacts #terms h4 {
    background-color: unset;
    padding: .5rem 0 .35rem;
    font-size: 1.15em;
  }

  #section-contacts #terms h4:after {
    display: none;
  }

  #section-contacts #terms p, #section-contacts #terms ul {
    height: initial;
    visibility: visible;
    opacity: 1;
    margin-bottom: .75rem;
    padding: 0;
    font-size: 1em;
  }

  .modal__btn {
    text-align: center;
    background-color: var(--color-bg-light);
    color: var(--color-text-primary);
    cursor: pointer;
    border: none;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: .5rem;
    right: .6rem;
  }

  .modal__btn-close:before {
    content: "✕";
    font-size: 1.4em;
    font-weight: 300;
    font-family: var(--font-body-secondary);
    color: currentColor;
    place-content: center;
    width: 100%;
    height: 100%;
    display: grid;
  }
}

@media screen and (width >= 1024px) {
  header {
    max-width: var(--content-width-xl);
    justify-content: space-between;
    align-items: center;
    margin-inline: auto;
    padding: .25em .5em;
    display: flex;
  }

  header #main-logo {
    font-size: .8rem;
  }

  header #main-logo:hover {
    opacity: .75;
    cursor: pointer;
  }

  #menu-hamburger, #menu-close {
    display: none;
  }

  header #top-nav {
    width: unset;
    max-width: unset;
    overflow: initial;
    background-color: #0000;
    border: none;
    line-height: 100%;
    transition: none;
    position: relative;
    transform: none;
  }

  header #top-nav.active {
    border-radius: unset;
    border: none;
    transition: none;
    transform: none;
  }

  header #top-menu {
    flex-direction: row;
    padding: 0;
  }

  header #top-menu .menu-item {
    letter-spacing: .11ch;
    cursor: pointer;
    border: none;
    outline: 1px solid #0000;
    margin-inline: 3px 0;
    padding: .4em 1.35em;
    font-size: .97rem;
    font-weight: 600;
    line-height: 1.1;
    transition: transform .3s;
  }

  header #top-menu .menu-item.active {
    outline: 1px solid var(--color-button-bg);
    background-color: var(--color-button-bg);
    text-shadow: none;
  }

  header #top-menu .menu-item:not(.active):hover {
    outline: 1px solid var(--color-text-primary);
  }

  #btn-service-description-prev, #btn-service-description-next {
    display: none;
  }

  #footer-nav > .nav-btn {
    cursor: pointer;
    transition: transform .25s;
  }

  #footer-nav:hover > .nav-btn {
    transform: translate3d(0, .35rem, 0);
  }

  .content-scroller {
    flex-direction: row;
    width: 500%;
    height: 100dvh;
    transition: left 1s;
  }

  .content-scroller[data-section] {
    top: 0;
  }

  .content-scroller[data-section="0"] {
    left: 0;
  }

  .content-scroller[data-section="1"] {
    left: -100vw;
  }

  .content-scroller[data-section="2"] {
    left: -200vw;
  }

  .content-scroller[data-section="3"] {
    left: -300vw;
  }

  .content-scroller[data-section="4"] {
    left: -400vw;
  }

  .section .section-content {
    max-width: var(--content-width-xl);
    margin-inline: auto;
  }

  #section-home .section-content {
    padding-inline: 1.5em;
    font-size: 1rem;
  }

  #greeting {
    padding-inline: .9rem;
    font-size: 1.25rem;
  }

  #greeting #greeting-message {
    font-size: 1em;
  }

  #greeting-motto {
    margin-bottom: 1.35rem;
    font-size: 3.5em;
    line-height: 1;
  }

  #greeting-subtitle {
    margin-bottom: 2rem;
    font-size: .9em;
    line-height: 1.5;
  }

  #greeting .btn {
    font-size: .8em;
  }

  #section-services .section-content {
    padding-top: max(15vh, 6rem);
    gap: .25em 2em;
    padding-inline: 4.25rem 1rem;
    font-size: 1.05rem;
  }

  #section-services .section-title {
    display: none;
  }

  #section-services #services-list {
    width: 41ch;
  }

  #section-services .service-name {
    font-size: 1.35em;
  }

  .service-name svg {
    width: 30px;
    height: 30px;
  }

  #section-services .service-details {
    max-width: min(90vw - 41ch, 800px);
  }

  #section-about > .section-content {
    padding: 4.5rem 1rem 3.15rem;
    font-size: .95rem;
  }

  #about-facts {
    gap: 1rem;
    margin: 0;
  }

  .fact-card {
    width: 250px;
    height: 270px;
    padding: 1rem;
    transition: all .2s;
  }

  .fact-card:hover {
    background-color: var(--color-card-light-hover-bg);
    place-content: center;
    transform: scale(1.05);
  }

  .fact-card:hover .card-face {
    display: none;
  }

  .fact-card:hover .card-back {
    display: block;
  }

  .fact-card .card-face {
    font-size: 1.2em;
  }

  .fact-card .card-face .card-title {
    font-size: 5rem;
    line-height: 5rem;
  }

  .fact-card .card-face .card-subtitle {
    margin-bottom: 1.5rem;
    font-size: 1.45em;
    line-height: 1.05;
  }

  .fact-card .card-back {
    font-size: .85rem;
  }

  .fact-card .card-back p {
    text-align: left;
    font-size: .95rem;
  }

  .fact-card .card-back p:nth-of-type(2) {
    margin-top: .75rem;
  }

  #about-projects .section-title {
    font-size: 1.175rem;
  }

  #section-features .section-title {
    margin-bottom: 1.75rem;
    font-size: 1.175rem;
  }

  #section-features .feature {
    font-size: 1rem;
  }

  #section-features .feature#f5 {
    margin-right: 2.75rem;
  }

  #section-features .feature#f6 {
    margin-left: 2.75rem;
  }

  #section-contacts.active {
    bottom: unset;
    z-index: unset;
    position: relative;
  }

  #section-contacts .section-content {
    justify-self: start;
    padding-top: 7.5rem;
    padding-left: 4rem;
  }

  #section-contacts .content-wrapper {
    grid-template-columns: 380px minmax(320px, 1fr);
  }

  #section-contacts .section-title {
    margin-bottom: .25rem;
    font-size: 1.16rem;
  }

  #section-contacts .btn {
    font-size: .975rem;
  }

  :root {
    --lightningcss-light: initial !important;
    --lightningcss-dark:  !important;
    color-scheme: light only !important;
  }
}

@media screen and (width >= 1368px) {
  #section-features .feature-right {
    margin-left: 3rem;
  }

  #section-features .feature-left {
    margin-right: 3rem;
  }

  #section-features .feature#f5 {
    margin-right: 3.5rem;
  }

  #section-features .feature#f6 {
    margin-left: 3.5rem;
  }
}

@media screen and (width >= 768px) and (height <= 767.999px) {
  #section-features .section-content {
    padding-top: 5rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    padding-inline: 3.5rem;
  }

  #section-features .section-title {
    grid-area: 1 / 1 / 2 / -1;
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  #section-features .logo-container {
    display: none;
  }

  #section-features.active .feature {
    opacity: 1;
    border: 1px solid;
    border-radius: 1rem;
    flex-direction: row;
    gap: .75rem;
    max-width: 50ch;
    margin: 0;
    padding: 1.15rem;
    transform: translate3d(0, 0, 0);
  }

  #section-features.active .feature-left {
    flex-direction: row-reverse;
  }

  #section-features .feature#f5, #section-features .feature#f6 {
    margin-inline: 0;
  }

  .feature#f1 {
    opacity: 0;
    grid-area: 2 / 1 / 3 / 2;
    transition: transform .5s ease-in .75s, opacity .5s ease-in .75s;
    transform: translate3d(-3rem, 0, 0);
  }

  .feature#f2 {
    opacity: 0;
    grid-area: 2 / 2 / 3 / 3;
    transition: transform .5s ease-in 1s, opacity .5s ease-in 1s;
    transform: translate3d(3rem, 0, 0);
  }

  .feature#f3 {
    opacity: 0;
    grid-area: 3 / 1 / 4 / 2;
    transition: transform .5s ease-in 1.25s, opacity .5s ease-in 1.25s;
    transform: translate3d(-2.5rem, 0, 0);
  }

  .feature#f4 {
    opacity: 0;
    grid-area: 3 / 2 / 4 / 3;
    transition: transform .5s ease-in 1.5s, opacity .5s ease-in 1.5s;
    transform: translate3d(2.5rem, 0, 0);
  }

  .feature#f5 {
    opacity: 0;
    -ms-transform: translate3d(-2rem, 0, 0);
    -o-transform: translate3d(-2rem, 0, 0), -moz-transform: translate3d(-2rem, 0, 0);
    grid-area: 4 / 1 / 5 / 2;
    margin-inline: 0;
    transition: transform .5s ease-in 1.75s, opacity .5s ease-in 1.75s;
    transform: translate3d(-2rem, 0, 0);
  }

  .feature#f6 {
    opacity: 0;
    grid-area: 4 / 2 / 5 / 3;
    margin-inline: 0;
    transition: transform .5s ease-in 2s, opacity .5s ease-in 2s;
    transform: translate3d(2rem, 0, 0);
  }

  #section-features.active .feature img {
    width: 6em;
    height: 5em;
    margin: 0;
  }

  #section-contacts #terms {
    margin-top: 1rem;
    font-size: .775rem;
  }
}

@media screen and (width >= 1024px) and (height <= 767.999px) {
  #greeting {
    padding-top: 2rem;
    font-size: 1.2rem;
  }

  #about-projects .section-title {
    margin-top: 1.5rem;
    font-size: 1.125rem;
  }

  .fact-card {
    height: unset;
    padding: .5rem;
  }

  .fact-card .card-face {
    padding-bottom: 2rem;
    font-size: 1.15em;
  }

  .fact-card .card-face .card-subtitle {
    margin-bottom: 1.2rem;
    font-size: 1.4rem;
  }

  .fact-card .card-back {
    padding-left: .5rem;
    font-size: .75rem;
  }

  #section-contacts .section-content {
    padding-top: 6.5rem;
  }
}

@keyframes fadeInBg {
  from {
    opacity: .2;
    transform: scale(1.08)rotate(-.02deg);
  }

  to {
    opacity: .45;
    transform: none;
  }
}

@keyframes sildeInFromBottom {
  0% {
    opacity: 0;
    transform: translate3d(0, 3rem, 0);
  }

  75% {
    opacity: 0;
    transform: translate3d(0, 2.5rem, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes sildeInFromTop {
  0% {
    opacity: 0;
    transform: translate3d(0, -3rem, 0);
  }

  75% {
    opacity: 0;
    transform: translate3d(0, -2.75rem, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes sildeInFromLeft {
  0% {
    opacity: 0;
    transform: translate3d(-3rem, 0, 0);
  }

  75% {
    opacity: 0;
    transform: translate3d(-2.75rem, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
/*# sourceMappingURL=index.2eb06021.css.map */
